import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Paginator } from 'primereact/paginator'
import { SpeedDial } from 'primereact/speeddial';

import { dialog, trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, simpleCheckboxEditor, validCell, SimpleText } from 'components';
import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';

import appConfig from 'config/appConfig.json';

export const SettingsAlertsDatatable = (props: any) => {
  const { apim, tableKey, title, resourceType } = props;

  const { t } = apim.di();
  const [expandedRows, setExpandedRows] = useState<any[] | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>( []);
  const [alertCategories, setAlertCategories] = useState<any[]>( []);
  const [data, setData] = useState<any[]>(rows ?? []);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [first, setFirst] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [searchParams] = useSearchParams();
  const sortField = searchParams.get('sortField') || '';
  const sortOrder = searchParams.get('sortOrder') === 'ASC' ? 'asc' : 'desc';

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: resourceType,
      notif: false,
      params: [
        {label: 'page', value: first / rowsPerPage + 1},
        {label: 'itemsPerPage', value: rowsPerPage},
        {label: `order[${sortField}]`, value: sortOrder},
      ],
      setLoading,
      success: (res: AxiosResponse) => {
        setTotalRecords(res.data['hydra:totalItems'])
        setRows(res?.data['hydra:member'])
      }
    } as IRequestParams);
  }, [first, rowsPerPage, resourceType, apim, sortField, sortOrder]);

  useEffect(() => {
    setData(rows);
  }, [rows]);

  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'alertCategories',
      setter: setAlertCategories,
      notif: false
    } as IRequestParams);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const notificationActiveBodyTemplate = (rowData: any) => validCell(rowData?.notificationActive);
  const actionsBodyTemplate = (rowData: any) => {
    let items = [];
    items.push({
      label: ucfirst(trans(t, 'edit')),
      icon: 'pi pi-pencil',
      className: 'bg-indigo-500',
      command: () => onEdit(rowData),
    });
    items.push({
      label: ucfirst(trans(t, 'system|actions.delete')),
      icon: 'pi pi-trash',
      className: 'bg-red-500',
      command: () => {
        dialog(t, {
          message: trans(t, 'system|confirmations.short'),
          accept: () => {
            if (rowData?.id) {
              apim.deleteEntity({
                resourceType: resourceType,
                id: rowData?.id,
                success: () => {
                  setData(data => data.filter(item => item.id !== rowData?.id));
                },
              } as IRequestParams);
            }
          },
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-text p-button-primary'
        });
      }
    })

    return <>
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40}
                 showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'}/>
    </>
  }
  const onEdit = (rowData: any) => {
    setEditMode(true);
    setExpandedRows([rowData]);
  };

  const renderForm = useCallback((rowData: any) =>
      <FormWrapper classes={'grid p-fluid'} submitClass={'pr-5'} resourceType={resourceType}
                   hideReload cancelLink data={rowData} callback={onSubmitCallback} listsOptions={{ alertCategory: alertCategories}} />
    , [alertCategories]) // eslint-disable-line react-hooks/exhaustive-deps

  const rowExpansionTemplate = (rowData : any) => {
    return (
      <>
        {editMode ? (
          renderForm(rowData)
        ) : (
          <div className={'flex flex-column w-12'}>
            <div className={'flex flex-wrap flex-row w-12 p-2'}>
              <div className={'flex flex-grow-1'}>
                <SimpleText text={rowData.code} title={ucfirst(trans(t, 'code'))}/>
              </div>
              <div className={'flex flex-grow-1'}>
                <SimpleText text={rowData.description} title={ucfirst(trans(t, 'description'))}/>
              </div>
              <div className={'flex flex-grow-1'}>
                <SimpleText text={ucfirst(trans(t, rowData.type))} title={ucfirst(trans(t, 'type'))}/>
              </div>
              <div className={'flex flex-grow-1'}>
                <SimpleText text={rowData.alertCategory?.label} title={ucfirst(trans(t, 'alertCategory'))}/>
              </div>
            </div>

            <div className={'flex flex-wrap flex-row w-12'}>
              <div className={'w-6 p-2'}>
                <Fieldset legend={ucfirst(trans(t, 'notification'))} className={'w-full'}>
                  <div className={'w-full mb-3'}>
                    <SimpleText text={rowData.title} title={ucfirst(trans(t, 'title'))} method={'html'}/>
                  </div>
                  <div className={'w-full'}>
                    <SimpleText text={rowData.content} title={ucfirst(trans(t, 'content'))} method={'html'}/>
                  </div>
                </Fieldset>
              </div>
              <div className={'w-6 p-2'}>
                <Fieldset legend={ucfirst(trans(t, 'email'))} className={'w-full'}>
                  <div className={'w-full mb-3'}>
                    <SimpleText text={rowData.mailTitle} title={ucfirst(trans(t, 'mailTitle'))} method={'html'} />
                  </div>
                  <div className={'w-full'}>
                    <SimpleText text={rowData.mailContent} title={ucfirst(trans(t, 'mailContent'))} method={'html'} />
                  </div>
                </Fieldset>
              </div>
            </div>

            <div className={'flex flex-wrap flex-row w-12'}>
              <div className={'w-6 p-2'}>
                <Fieldset legend={ucfirst(trans(t, 'specificAlert'))} className={'w-full'}>
                  <div className={'w-full mb-3'}>
                    <SimpleText text={rowData.clientTitle} title={ucfirst(trans(t, 'clientTitle'))} method={'html'}/>
                  </div>
                  <div className={'w-full'}>
                    <SimpleText text={rowData.clientContent} title={ucfirst(trans(t, 'clientContent'))} method={'html'}/>
                  </div>
                </Fieldset>
              </div>
              <div className={'w-6 p-2'}>
                <Fieldset legend={ucfirst(trans(t, 'specificEmail'))} className={'w-full'}>
                  <div className={'w-full mb-3'}>
                    <SimpleText text={rowData.clientMailTitle} title={ucfirst(trans(t, 'clientMailTitle'))} method={'html'} />
                  </div>
                  <div className={'w-full'}>
                    <SimpleText text={rowData.clientMailContent} title={ucfirst(trans(t, 'clientMailContent'))} method={'html'}/>
                  </div>
                </Fieldset>
              </div>
            </div>
            <div className={'flex flex-wrap flex-row w-12 p-2'}>
              <div className={'flex flex-grow-1'}>
                <SimpleText value={rowData.subscriptionConfigurable} method={'bool'} title={trans(t, 'subscriptionConfigurable')}/>
              </div>
              <div className={'flex flex-grow-1'}>
                <SimpleText value={rowData.sendToDossierUser} method={'bool'} title={trans(t, 'sendToDossierUser')}/>
              </div>
              <div className={'flex flex-grow-1'}>
                <SimpleText value={rowData.sendToDossierExperts} method={'bool'} title={trans(t, 'sendToDossierExperts')}/>
              </div>
              <div className={'flex flex-grow-1'}>
                <SimpleText value={rowData.notificationActive} method={'bool'} title={trans(t, 'notificationActive')}/>
              </div>
              <div className={'flex flex-grow-1'}>
                <SimpleText value={rowData.active} method={'bool'} title={trans(t, 'active')}/>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  const onNewRow = (_rows: any[], callback: any) => {
    setLoading(true)
    apim.postEntity({
      resourceType: resourceType,
      data: { active : true },
      setLoading,
      success: (res: AxiosResponse) => {
        setData([res.data,...data ]);
        callback([..._rows, ...[res.data]]);

        // Toggle edit on added row.
        setExpandedRows([res.data]);
        onEdit(res.data);
      }
    } as IRequestParams).then();
  };
  const onSubmitCallback = (newData: any) => {
    setEditMode(false);
    setData((data) =>
      data.map((item) => (item.id === newData?.id ? newData : item))
    )
  }

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRowsPerPage(event.rows);
  };

  return (
    <>
      <DatatableWrapper resourceType={resourceType} rows={data} stripedRows={!editMode} tableKey={tableKey} totalRecords={totalRecords} additionalClassNames={'p-datatable-no-hover'}
                        noFilters expandedRows={expandedRows} paginator={false} isLoading={loading} title={title} rowExpansionTemplate={rowExpansionTemplate}
                        onNew={onNewRow} onRowEditCallback={onEdit} onRowToggle={(e: any) => {setExpandedRows(e.data)}}>
        <Column expander={true} style={{width: '20px'} }/>
        <Column field={'code'} header={ucfirst(trans(t, 'code'))} sortable align={'center'} style={{width: '50px'}}/>
        <Column field={'description'} header={ucfirst(trans(t, 'description'))} sortable />
        <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
                style={{width: '5vw'}}/>
        <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
                style={{width: '5vw'}}/>
        <Column field={'notificationActive'} header={trans(t, 'notificationActive')} sortable align={'center'} body={notificationActiveBodyTemplate}
                editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|sendNotification')})}
                style={{width: '50px'}}/>
        <Column field={'active'} header={trans(t, 'active')} sortable align={'center'} body={activeBodyTemplate}
                editor={(options) => simpleCheckboxEditor(options, {label: ucfirst(trans(t, 'table|active'))})}
                style={{width: '50px'}}/>
        <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate}
                style={{ width: '100px' }}/>
      </DatatableWrapper>
      <Paginator first={first} rows={rowsPerPage} totalRecords={totalRecords} rowsPerPageOptions={appConfig.pagination.default.rowsPerPageOptions}
                 onPageChange={onPageChange} template={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'}
                 currentPageReportTemplate={trans(t, 'result', (totalRecords || 1)) + ' {first} à {last} (sur ' + (totalRecords || 0) + ')'}
      />
    </>
  );
};
