import React from 'react';
import { Column } from 'primereact/column';

import { trans, ucfirst } from 'utilities';
import {
  simpleCheckboxEditor, DatatableWrapper, dateTimeCell, percentCell, percentEditor, validCell, maskEditor
} from 'components';

export const SettingsAverageMarketRatesDatatable = (props: any) => {
  const { lazyConfig, apim, tableKey, title, resourceType } = props;
  const { t } = apim.di();

  const lazyC = { ...{ sortField: 'date', sortOrder: -1 }, ...lazyConfig };
  const dateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date, { format: 'DD/MM/YYYY' });
  const rateBodyTemplate = (rowData: any) => percentCell(rowData.rate);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey} lazyConfig={lazyC} title={title} editMode
                      editFields={['rate', 'date', 'active']} noFilters noGlobalFilter>
      <Column field={'rate'} header={ucfirst(trans(t, 'rate'))} sortable body={rateBodyTemplate}
              editor={(options) => percentEditor(options, { label: '%', maxFractionDigits: 3 })}
              style={{ width: '15vw' }} />
      <Column field={'date'} body={dateBodyTemplate} header={ucfirst(trans(t, 'date'))} sortable align={'center'}
              editor={(options) => maskEditor(options, {label: trans(t, 'date'), isDate: true})}/>
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
              style={{ width: '15vw' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
              style={{ width: '15vw' }} />
      <Column field={'active'} header={trans(t, 'active')} sortable align={'center'} body={activeBodyTemplate}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'table|active') })}
              style={{ width: '5vw' }} />
    </DatatableWrapper>
  );
};
