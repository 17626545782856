import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { loginCallback, useApim } from 'services';
import { getQueryParam, trans } from 'utilities';
import { useUserState } from 'states';

import appUri from 'config/appUri.json';

export const LoginCallback = () => {
  const toast = useRef<any>(undefined);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userState = useUserState();
  const apim = useApim();

  // Early Dependency injection !
  useEffect(() => {
    if (!apim.navigate()) {
      apim.setToast(toast, 'error');
      apim.setNavigate(() => navigate);
      apim.setT(() => t);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goRefresh = () => navigate(appUri.home);

  useEffect(() => userState.checkAuth(goRefresh, userState), []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const code = getQueryParam('code');
    const state = getQueryParam('state');
    const error = getQueryParam('error');
    const errorDescription = getQueryParam('error_description');

    if (error && errorDescription === 'user is blocked') {
      apim.displayError(trans(t, 'notification|user.blocked.summary'), trans(t, 'notification|user.blocked.details'))
    } else if (error){
      apim.displayError(trans(t, 'notification|user.login.summary'), trans(t, 'notification|user.login.details'))
    } else if (code && state) {
      loginCallback(apim, code, state, userState);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'pages-body login-page flex flex-column lg:flex-row'}>
      <Toast content={''} ref={toast} position={'top-center'}/>
      <div className={'a8-mbg flex flex-row align-items-center'}>
        <div className={'flex flex-column align-items-center w-full'}>
          <div className={'a8-sub-header mb-5 pb-5'}>
            <span className={'a8-logo'}></span>
            <h1 className={'fw-400 text-primary text-4xl md:text-6xl text-center'}>{trans(t, 'system|app.baseline')}</h1>
          </div>
          <div className={'login-form ml-auto mr-auto'}>
            <div className={'pages-panel card'}>
              <div className={'pages-header'}>
                <img id={'app-logo'} src={'/assets/images/logos/logo.svg'} alt={'Atome 8 logo clair'}/>
              </div>
              <h4 className={'fw-400'}>{trans(t, 'system|welcome')}</h4>
              <div className={'pages-detail mb-6 px-6'}>{trans(t, 'system|welcome.loginCallback')}</div>
              <Button className={'p-button-text text-ucfirst'} onClick={goRefresh} type={'button'} label={trans(t, 'refresh')} icon={'pi pi-refresh'} />
            </div>
          </div>
        </div>
      </div>
      <div className={'a8-lbg'}></div>
    </div>
  );
};
