import { trans } from 'utilities';
import { useThemeState } from 'states';

const enableTicketCollector = process.env.REACT_APP_ENABLE_TICKET_COLLECTOR || '1';

export const HelpMenu = (props: any) => {
  const { apim } = props;
  const { t } = apim.di();
  const themeState = useThemeState();

  let items = [];
  if (enableTicketCollector) {
    // Only display the notify bug item on live mode.
    items.push({
      label: trans(t, 'menu|menu.help.header'),
      icon: 'exclamation-circle',
      items: [
        {
          icon: 'exclamation-circle',
          label: trans(t, 'menu|menu.help.notifyBug'),
          // Customize toggle button for the ticket collector
          // @see TicketCollector.tsx
          id: 'a8-ticket-collector-toggle',
          command: () => themeState.toggleTicketCollector()
        }
      ]
    })
  }

  return items;
};
