import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { accountLinkCallback, useApim } from 'services';
import { getQueryParam, trans } from 'utilities';
import { useUserState } from 'states';

import appUri from 'config/appUri.json';

export const AccountLinkCallback = (props: any) => {
  const toast = useRef<any>(undefined);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userState = useUserState();
  const apim = useApim();

  // Early Dependency injection !
  useEffect(() => {
    if (!apim.navigate()) {
      apim.setToast(toast, 'error');
      apim.setNavigate(() => navigate);
      apim.setT(() => t);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goRefresh = () => navigate(appUri.home);

  // useEffect(() => userState.checkAuth(goRefresh, userState), []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const code = getQueryParam('code');
    const state = getQueryParam('state');
    const sessionState = getQueryParam('session_state');

    if (code && state && sessionState) {
      accountLinkCallback(apim, props?.type ?? 'msgraph', code, state, sessionState, userState);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'pages-body login-page flex flex-row'}>
      <Toast content={''} ref={toast} position={'bottom-center'} />

      <div className={'a8-mbg flex flex-row'}>
        <div className={'align-self-center mt-auto mb-auto'}>
          <div className={'a8-sub-header mb-5 pb-5'}>
            <span className={'a8-logo'}></span>
            <h1 className={'fw-400 text-primary text-center'}>{trans(t, 'system|app.baseline')}</h1>
          </div>
          <div className={'login-form ml-auto mr-auto'}>
            <div className={'pages-panel card'}>
              <div className={'pages-header px-3 py-1'}>
                <div className={'text-4xl'}>{trans(t, 'system|app.name').toUpperCase()}</div>
              </div>
              <h4 className={'fw-400'}>{trans(t, 'system|welcome') }</h4>
              <div className={'pages-detail mb-6 px-6'}>{trans(t, 'system|welcome.loginCallback')}</div>
              <Button onClick={goRefresh} type={'button'} label={trans(t, 'cancel')} className={'p-button-text text-ucfirst'} icon={'pi pi-home'} />
            </div>
          </div>
        </div>
      </div>

      <div className={'a8-lbg'}></div>
    </div>
  );
};
