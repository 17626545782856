import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { SpeedDial } from 'primereact/speeddial';
import { Panel } from 'primereact/panel';

import { asDate, dateString, dialog, humanFileSize, iri, isValidUUID, removeLocalStorageItem, trans, ucfirst } from 'utilities';
import { useAppState, useFormState, useUserState } from 'states';
import { dateTimeCell, DocumentViewer, Loader, multilinesCell, PageLoader, userCell, validCell, wysiwygCell } from 'components';
import { IRequestParam, IRequestParams, isAdmin, isClient, isSuperAdmin, useApim } from 'services';
import { FormWrapper, UncontrolledAPIAutocompleteField, UncontrolledCheckboxField, UncontrolledStaticListField, UncontrolledTriStateField } from 'forms';
import { UploadedFiles } from './UploadedFiles';
import {
  formKey, parentKey as parentKeyUpload, parentKeyGed,
  getFile, getFiles, removeFile, removeFiles, updateFile, subscribeToHub
} from './GedHelper';

import { forEach, isArray, isBoolean, isEmpty, isString, omit, pick } from 'lodash';
import apimConfig from 'config/apimConfig.json';
import appUri from 'config/appUri.json';
import appConfig from 'config/appConfig.json';
import cloudFile from 'assets/images/baseline-cloud_upload-24px.svg';

export const DocumentsList = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // We have to keep this even if we also get some from URL (for simpler page title, etc.).
  const { dossier, company } = props;

  const apim = useApim();
  const userState = useUserState();
  const formState = useFormState();
  const appState = useAppState();

  const { t, navigate } = apim.di();

  const vboMenuRef = useRef<any>(null);
  const editModalRef = useRef<any>(null);
  const modalRef = useRef<any>(null);
  const viewerRef = useRef<any>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [editModalLoading, setEditModalLoading] = useState<boolean>(false);
  const [editModalIndex, setEditModalIndex] = useState<string>('');
  const [documentPreview, setDocumentPreview] = useState<any>();

  const [types, setTypes] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);

  let globalSearchTimer: ReturnType<typeof setTimeout>;
  const [loading, setLoading] = useState<boolean>(false);
  const [calling, setCalling] = useState<boolean>(false);
  const [selected, setSelected] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  // Keep separated lazy filters from other filters for more visibility.
  const [lazyState, setlazyState] = useState<any>({
    first: searchParams.has('page') ? parseInt(searchParams.get('page') as string) * (searchParams.has('perPage') ? parseInt(searchParams.get('perPage') as string) : appConfig.pagination.default.itemsPerPage) : 0,
    rows: searchParams.has('perPage') ? parseInt(searchParams.get('perPage') as string) : appConfig.pagination.default.itemsPerPage,
    page: searchParams.has('page') ? parseInt(searchParams.get('page') as string) : 0,
    sortField: searchParams.has('sortField') ? searchParams.get('sortField') : 'created',
    sortOrder: searchParams.has('sortOrder') ? (searchParams.get('sortOrder') === 'ASC' ? 1 : -1) : -1,
    filters: { global: { value: searchParams.has('search') ? searchParams.get('search') : null, matchMode: FilterMatchMode.CONTAINS } }
  });
  const [filters, setFilters] = useState<any>({
    dossiers: searchParams.has('dossiers') ? searchParams.get('dossiers')?.split(',') : (isValidUUID(dossier?.id) ? [dossier?.id] : []),
    companies: searchParams.has('companies') ? searchParams.get('companies')?.split(',') : (isValidUUID(company?.id) ? [company?.id] : []),
    modules: searchParams.has('modules') ? searchParams.get('modules')?.split(',') : [],
    types: searchParams.has('types') ? searchParams.get('types')?.split(',') : [],
    active: searchParams.has('active') ? (searchParams.get('active') ?? '').toLowerCase() === 'true' ? true : null : (isSuperAdmin() ? null : true),
    issued: searchParams.has('issued') ? (searchParams.get('issued') ?? '').toLowerCase() === 'true' ? true : null : (isSuperAdmin() ? null : true),
  });

  const allColumns: any[] = [
    {value: 'date', translated: ucfirst(trans(t, 'date'))},
    {value: 'description', translated: ucfirst(trans(t, 'description'))},
    {value: 'extension', translated: ucfirst(trans(t, 'extension'))},
    {value: 'size', translated: ucfirst(trans(t, 'size'))},
    {value: 'filename', translated: ucfirst(trans(t, 'filename'))},
    {value: 'created', translated: ucfirst(trans(t, 'upload_date'))},
    {value: 'changed', translated: ucfirst(trans(t, 'changed_date'))},
    {value: 'dossiers', translated: ucfirst(trans(t, 'dossier', 2))},
    {value: 'companies', translated: ucfirst(trans(t, 'company', 2))},
    {value: 'user', translated: ucfirst(trans(t, 'author'))},
    {value: 'source', translated: ucfirst(trans(t, 'source'))},
    {value: 'fileStatus', translated: ucfirst(trans(t, 'fileStatus'))},
  ];

  if (isAdmin()) {
    allColumns?.push({value: 'deleted', translated: ucfirst(trans(t, 'deleted_date'))});
    allColumns?.push({value: 'active', translated: ucfirst(trans(t, 'active'))});
  }

  const [columns, setColumns] = useState<any[]>(['date', 'description', 'extension', 'size', 'filename', 'created', 'dossiers', 'companies', 'active']);
  const [userConfigId, setUserConfigId] = useState<string|null>(null);

  // Update Page Header & check hub subscription.
  useEffect(() => {
    let pageTitle: string = trans(t, 'menu|pages.title.ged');
    if (dossier?.id) pageTitle = trans(t, 'menu|pages.title.dossier.ged', 1, dossier.title);
    if (company?.id) pageTitle = trans(t, 'menu|pages.title.societe.ged', 1, company.latestVersion?.raisonSociale);
    appState.setPageTitle(pageTitle);

    let breadcrumb: any[] = [{ label: trans(t, 'ged') }];
    if (dossier?.id) breadcrumb = [
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: appState.dossier()?.title, to: appUri.dos.page.replace(':id', dossier?.id)},
      { label: trans(t, 'ged') }
    ];
    if (company?.id) breadcrumb = [
      { label: trans(t, 'company', 2), to: appUri.cie.cli.list },
      { label: company ? company.latestVersion?.raisonSociale : trans(t, 'company') }
    ];
    appState.setBreadcrumb(breadcrumb);

    // Check hub subscription.
    formState.setFieldData(formKey, 'userId', null, userState.id());
    subscribeToHub(apim, formState);

    if (dossier?.id || company?.id) return;

    appState.setPageActions([]);
  }, [dossier?.id, company?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Initial load of modules / types.
  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'documentTypes',
      params: [
        {label: 'order[name]', value: 'asc'},
        {label: 'itemsPerPage', value: 500}
      ],
      setter: setTypes
    } as IRequestParams).then(() => {
      apim.fetchEntities({
        resourceType: 'modules',
        params: [
          {label: 'exists[parent]', value: 'false'},
          {label: 'order[label]', value: 'asc'},
          {label: 'itemsPerPage', value: 500}
        ],
        setter: setModules,
        setLoading
      } as IRequestParams).then();
    });

    // Let's clean cached data.
    return () => {
      forEach(Object.keys(localStorage), (key: string) => {
        if (key.startsWith('a8form#a8_ged')) removeLocalStorageItem(key, false);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Initial load of current user configs if any (displayed columns, ...).
  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'userContextualConfigs',
      notif: false,
      params: [
        {label: 'key', value: 'ged_displayed_columns'},
        {label: 'user', value: userState.id()}
      ],
      setLoading,
      success: (res: AxiosResponse) => {
        if (!res?.data || (res?.data['hydra:member'] ?? []).length < 1) return;

        setUserConfigId(res?.data['hydra:member'][0].id);
        setColumns(res?.data['hydra:member'][0].metadata);
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Always pick param from props, then URL, then fallback on filters state.
  const buildSearchParams = (checkIssued: boolean | null = false) => {
    const params: IRequestParam[] = [];

    // Global search filter.
    if (searchParams.has('search')) {
      const needle = searchParams.get('search');
      if (!isEmpty(needle)) params.push({label: 'search', value: needle});
    } else {
      if (lazyState?.filters?.global && !isEmpty(lazyState.filters.global.value)) params.push({label: 'search', value: lazyState.filters.global.value});
    }

    // Other filters.
    if (isValidUUID(dossier?.id)) {
      params.push({label: 'byDossier', value: dossier.id});
    } else {
      if (searchParams.has('dossiers')) {
        const list = searchParams.get('dossiers');
        if (!isEmpty(list)) {
          forEach((list?.split(',') ?? []), (listItem: string) => {
            if (isValidUUID(listItem)) params.push({label: 'dossiers.id[]', value: listItem});
            if ('none' === listItem) params.push({label: 'hasDossiers', value: false});
          });
        }
      } else {
        forEach((filters?.dossiers ?? []), (d: any) => {
          if (isValidUUID(d?.id ?? d)) params.push({label: 'dossiers.id[]', value: d.id ?? d});
          if ('none' === d?.value) params.push({label: 'hasDossiers', value: false});
        });
      }
    }

    if (isValidUUID(company?.id)) {
      params.push({label: 'companies.id[]', value: company.id});
    } else {
      if (searchParams.has('companies')) {
        const list = searchParams.get('companies');
        if (!isEmpty(list)) {
          forEach((list?.split(',') ?? []), (listItem: string) => {
            if (isValidUUID(listItem)) params.push({label: 'companies.id[]', value: listItem});
            if ('none' === listItem) params.push({label: 'hasCompanies', value: false});
          });
        }
      } else {
        forEach((filters?.companies ?? []), (listItem: any) => {
          if (isValidUUID(listItem?.id ?? listItem)) params.push({label: 'companies.id[]', value: listItem.id ?? listItem});
          if ('none' === listItem?.value) params.push({label: 'hasCompanies', value: false});
        });
      }
    }

    if (searchParams.has('modules')) {
      const list = searchParams.get('modules');
      if (!isEmpty(list)) {
        forEach((list?.split(',') ?? []), (listItem: string) => {
          if (isValidUUID(listItem)) params.push({label: 'modules.id[]', value: listItem});
          if ('none' === listItem) params.push({label: 'hasModules', value: false});
        });
      }
    } else {
      // Do not query on modules if all are selected.
      if ((filters?.modules ?? []) - 1 < modules?.length) {
        forEach((filters?.modules ?? []), (listItem: any) => {
          if (isValidUUID(listItem?.id ?? listItem)) params.push({label: 'modules.id[]', value: listItem?.id ?? listItem});
          if ('none' === (listItem?.id ?? listItem)) params.push({label: 'hasModules', value: false});
        });
      }
    }

    if (searchParams.has('types')) {
      const list = searchParams.get('types');
      if (!isEmpty(list)) {
        forEach((list?.split(',') ?? []), (listItem: string) => {
          if (isValidUUID(listItem)) params.push({label: 'typeId[]', value: listItem});
          if ('none' === listItem) params.push({label: 'hasModules', value: false});
        });
      }
    } else {
      // Do not query on types if all are selected.
      if ((filters?.types ?? []) - 1 < types?.length) {
        forEach((filters?.types ?? []), (listItem: any) => {
          if (isValidUUID(listItem?.id ?? listItem)) params.push({label: 'typeId[]', value: listItem?.id ?? t});
          if ('none' === (listItem?.id ?? listItem)) params.push({label: 'hasModules', value: false});
        });
      }
    }

    if (searchParams.has('active')) {
      const val = searchParams.get('active') ?? '';
      if (['true', 'false'].includes(val.toLowerCase())) params.push({label: 'active', value: 'true' === val.toLowerCase()});
    } else {
      if (filters?.active) params.push({label: 'active', value: filters?.active});
    }

    if (searchParams.has('issued')) {
      const val = searchParams.get('issued') ?? '';
      if (['true', 'false'].includes(val.toLowerCase())) params.push({label: 'issued', value: 'true' === val.toLowerCase()});
    } else {
      if (filters?.issued) params.push({label: 'issued', value: filters?.issued});
    }

    return params;
  };

  const loadData = (initial: boolean) => {
    setLoading(true);

    const params: IRequestParam[] = [
      {label: 'page', value: lazyState.page + 1},
      {label: 'itemsPerPage', value: lazyState.rows},
      {label: 'order[' + lazyState.sortField + ']', value: lazyState.sortOrder === -1 ? 'desc' : 'asc'},
    ];

    // Handle datatable filters.
    forEach((buildSearchParams()), (p: IRequestParam) => params.push(p));

    apim.fetchEntities({
      resourceType: 'documentsIndex',
      params,
      setLoading,
      cache: false,
      success: (res: AxiosResponse) => {
        if (!res?.data) return;

        // Handle issued check first.
        if (initial && filters?.issued === true) {
          if ((res.data['hydra:member'] ?? []).length === 0) {
            // Let's trigger a new request if none of document are issued
            // by simply updating the filter state to null.
            updateSearchParams([{ key: "issued", action: "delete" }]);
            return setFilters({...filters, ...{issued: null}});
          }
        }

        setTotalRecords(res.data['hydra:totalItems']);
        const _data: any[] = res.data['hydra:member'].map((r: any) => ({
          ...r,
          ...{ dateSort: dateString(r.updated, 'YYYY-MM-DD HH:mm:ss') }
        }));

        formState.setFieldData(formKey, 'files', parentKeyGed, _data);
      }
    } as IRequestParams).then();
  };

  useEffect(() => {
    loadData(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Lazy load data from API.
  useEffect(() => {
    loadData(false);
  }, [lazyState, filters, dossier?.id, company?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateSearchParams = (newParams: any[]) => {
    const _searchParams = new URLSearchParams(searchParams);

    newParams.map((newParam: {key: string, value: any | undefined, action: string | undefined}) => {
      if (newParam.action === 'delete') {
        return _searchParams.delete(newParam.key);
      }

      let paramValue: any = newParam.value;
      if (isArray(paramValue)) {
        // Try to extract an identifier or a value from the array.
        paramValue = newParam.value.map((item: any) => {
          if (isString(item)) return item;
          return item?.id ?? item?.value ?? null;
        });

        if (paramValue === null) {
          console.error('Can not get id or value for array : ' + paramValue);
        }
      }

      return _searchParams.set(newParam.key, paramValue);
    });
    setSearchParams(`?${_searchParams}`);
  };

  const onPage = (event: any) => {
    event.filters = lazyState.filters;
    setlazyState({...lazyState, ...event});

    // Add the page parameter to the URL.
    updateSearchParams([{key: 'page', value: event.page}, {key: 'perPage', value: event.rows}]);
  };

  const onSort = (event: any) => {
    event.first = 0;
    event.page = 0;
    event.filters = lazyState.filters;
    setlazyState({...lazyState, ...event});

    // Add the sort parameter to the URL.
    updateSearchParams([{key: 'sortField', value: event.sortField}, {key: 'sortOrder', value: (event.sortOrder === -1 ? 'DESC' : 'ASC')}]);
  };

  const onGlobalFilter = (event: any) => {
    const value = event.target.value;
    if (value.length === 1) return;

    let _ls = { ...lazyState };
    _ls.filters['global'].value = value;

    if (globalSearchTimer) clearTimeout(globalSearchTimer);

    globalSearchTimer = setTimeout(() => {
      _ls.first = 0;
      _ls.page = 0;
      setlazyState(_ls);

      // Add the search parameter to the URL.
      if (value.length > 0) {
        updateSearchParams([{key: 'search', value}, {key: 'page', action: 'delete'}]);
      } else {
        updateSearchParams([{key: 'search', action: 'delete'}]);
      }
    }, 500);
  };

  /**
   * Load a document preview for a given document entity.
   *
   * @param document
   */
  const loadPreview = (document: any) => {
    setDocumentPreview(null);

    if (document?.status === 'unloaded' || document?.fileStatus === 'unloaded') {
      // We need to load the document.
      // When the document is not loaded yet (from Pappers, etc.).
      apim.call({
        resourceType: 'documents',
        action: 'load',
        id: document.id,
        method: 'get',
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          setDocumentPreview({...document, ...{uri: res?.data.url}});
        }
      } as IRequestParams).then();

    } else if ((document.urlExpiration === null || document.urlExpiration === undefined) || Date.now() > asDate(document.urlExpiration).getTime()) {
      // Document URL is expired and must be regenerated.
      // Call the document endpoint to refresh the URL.
      apim.fetchEntity({
        resourceType: 'documents',
        id: document.id,
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          setDocumentPreview({...document, ...{uri: res?.data.url}});
        }
      } as IRequestParams).then();

    } else {
      // We can use the indexed document URL freely.
      setDocumentPreview({...document, ...{uri: document.url}});
    }
  }

  const onPreviewClick = (file: any) => {
    const document: any = getFile(formState, file.id, parentKeyGed);
    loadPreview(document);
    setModalVisible(true);
  };

  const download = (file: any) => {
    const _download = (_file: any) => {
      const link = document.createElement('a');
      link.href = _file.url;
      link.download = _file.filename;
      link.target = '_blank';
      link.rel = 'noreferrer';

      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    };

    const doc: any = getFile(formState, file.id, parentKeyGed);
    if (doc?.status === 'unloaded' || doc?.fileStatus === 'unloaded') {
      // We need to load the document.
      // When the document is not loaded yet (from Pappers, etc.).
      apim.call({
        resourceType: 'documents',
        action: 'load',
        id: doc.id,
        method: 'get',
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          _download({...doc, ...{url: res?.data.url}});
        }
      } as IRequestParams).then();

    } else if (doc?.urlExpiration !== null && Date.now() > asDate(doc?.urlExpiration).getTime()) {
      // Document URL is expired and must be regenerated.
      // Call the document endpoint to refresh the URL.
      apim.fetchEntity({
        resourceType: 'documents',
        id: doc.id,
        cache: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          _download({...doc, ...{url: res?.data.url}});
        }
      } as IRequestParams).then();

    } else {
      // We can use the indexed document URL freely.
      _download(doc);
    }
  };

  const [uploadProgress, setUploadProgress] = useState<any[]>([]);
  const onDrop = (droppedFiles: any) => {
    formState.setLoading(formKey, true);
    let filesCount = 0;

    droppedFiles.map((file: any) => {
      filesCount++;

      if (getFiles(formState, parentKeyUpload, false, false).filter((f: any) => f.name === file.name).length === 0) {
        // Post (async) the file to APIM.
        const formData = new FormData();
        formData.append('file[]', file, file.name);
        formData.append('type', 'document');
        apim.postEntity({
          resourceType: 'fileUploads',
          data: formData,
          notif: false,
          paramType: 'postParams',
          headers: {'A8-Context': JSON.stringify({
              type: 'GED',
              userId: formState.getFieldData(formKey, 'userId')
            })},
          progress: (event: any) => {
            const _uploadProgress = uploadProgress;
            _uploadProgress[file.name] = (event.loaded / event.total) * 100;
            setUploadProgress(_uploadProgress);
          },
          success: (res: AxiosResponse) => {
            if (!res?.data?.id) return;

            const _file: any = {
              id: res.data.id,
              path: file.path,
              name: file.name,
              source: 'local',
              date: new Date().toISOString(),
              dateSort: new Date().toISOString(),
              active: false,
              status: 'uploading'
            };

            // Pre-fill dossier & company fields
            if (company?.id) {
              _file.companies = [{...company, ...{name: company.latestVersion?.raisonSociale}}];
            }
            if (dossier?.id) {
              _file.dossiers = [dossier];
            }

            updateFile(formState, _file);
          }
        } as IRequestParams).then();
      }

      if (filesCount === droppedFiles.length) {
        formState.setLoading(formKey, false);
        // Tricky DT refresh :p
        setUploadProgress(uploadProgress);
      }

      return true;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: apimConfig.default.maxUploadSize * 1000000
  });

  const droppedFiles = () =>
      getFiles(formState, parentKeyUpload, false,false).length > 0 && (
        <div>
          <div className={'font-weight-bold text-center'}>
            {trans(t, 'system|dropzoneFilesToUploadTitle')}
          </div>

          <UploadedFiles formKey={formKey} formState={formState} apim={apim} uploadProgress={uploadProgress} types={types}/>
        </div>
      )
  ;

  // Body templates.
  const dossierBodyTemplate = (rowData: any) => multilinesCell(rowData.dossiers, 'name');
  const companyBodyTemplate = (rowData: any) => multilinesCell(rowData.companies, 'name');
  const labelBodyTemplate = (rowData: any) => {
    return <>
      {rowData?.deleting ? (
        <div className={'first-line flex align-items-center'}>
          <span className={'mr-3 qe-icon-wrapper'}><i className={'pi pi-spin pi-spinner'}/></span>
          <Button label={rowData?.type?.name ?? rowData.title ?? rowData.filename} link onClick={() => onPreviewClick(rowData)} className={'text-left'}/>
        </div>
      ) : (
        <div className={'flex'}>
          {!isClient() && rowData?.type?.name?.includes('FEC') && (
            <div className={'flex align-items-center'}>
              <Button className={'a8-analyze-btn'} icon={'pi pi-replay'} rounded text
                      disabled={!rowData?.type?.name?.includes('FEC')}
                      aria-label={ucfirst(trans(t, 'system|triggerAnalyze'))} onClick={() => {
                dialog(t, {
                  message: trans(t, 'system|analyze.confirmation.fec'),
                  accept: () => {
                    apim.patchEntity({
                      resourceType: 'documents',
                      id: rowData?.id,
                      data: {
                        metadata: {
                          trigger_fec_analyze: new Date().toISOString(),
                          trigger_fec_analyze_override: true
                        }
                      },
                    } as IRequestParams).then();
                  }
                })
              }}
              />
              <Tooltip target={'.a8-analyze-btn'} position={'right'} content={ucfirst(trans(t, 'system|triggerAnalyze'))}/>
            </div>
          )}

          <div className={'flex flex-column justify-content-center'}>
            <Button className={'text-left p-0'} style={{wordBreak: 'break-all'}} label={rowData?.type?.name ?? rowData.title ?? rowData.filename} link onClick={() => onPreviewClick(rowData)}/>
            {rowData?.type?.name && rowData?.title && (
              <div className={classNames('text-sm', 'mt-2')}>{rowData?.title}</div>
            )}
            {!rowData?.type?.name && (
              <div className={classNames('text-xs text-danger mt-2')}>{trans(t, 'missingType')}</div>
            )}
          </div>
        </div>
      )}
    </>
  }

  const filenameBodyTemplate = (rowData: any) => <div className={'block text-xs'} style={{wordBreak: 'break-all'}}>{rowData.filename}</div>;
  const sizeBodyTemplate = (rowData: any) => <span>{rowData.size > 0 ? humanFileSize(rowData.size, true, 2) : 'N/A'}</span>;
  const dateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date, { format: rowData?.type?.dateYear === true ? 'YYYY' : 'DD/MM/YYYY' });
  const descriptionBodyTemplate = (rowData: any) => wysiwygCell(rowData?.description);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const deletedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.deleted);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const sourceBodyTemplate = (rowData: any) => <div className={'block'} style={{wordBreak: 'break-all'}}>{rowData.source?.toUpperCase()}</div>;
  const fileStatusBodyTemplate = (rowData: any) => validCell(rowData.fileStatus === 'loaded');
  const userBodyTemplate = (rowData: any) => userCell(rowData?.author, {
    url: appUri.usr.page.replace(':id', rowData?.author?.id),
    label: trans(t, 'seeDetails')
  });

  // Editor templates.
  const resetDefault = () => {
    setlazyState({
      first: 0,
      rows: appConfig.pagination.default.itemsPerPage,
      page: 0,
      sortField: 'updated',
      sortOrder: -1,
      filters: { global: { value: null, matchMode: FilterMatchMode.CONTAINS } }
    });

    updateSearchParams([
      {key: 'dossier', action: 'delete'},
      {key: 'companies', action: 'delete'},
      {key: 'modules', action: 'delete'},
      {key: 'types', action: 'delete'},
      {key: 'active', action: 'delete'},
      {key: 'issued', action: 'delete'}]
    );

    setFilters({
      dossiers: isValidUUID(dossier?.id) ? [dossier?.id] : [],
      companies: isValidUUID(company?.id) ? [company?.id] : [],
      modules: [],
      types: [],
      active: isSuperAdmin() ? null : true,
      issued: isSuperAdmin() ? null : true,
    });
    setSelected([]);
  };

  const dossiersEditorItemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.name}</div>
          {option.identifier && (<small className={'pl-2'}>({option.identifier})</small>)}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const companiesEditorItemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.name}</div>
          {option.siren && (<small className={'pl-2'}>({option.siren})</small>)}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const updateFilters = (filter: {key: string, value: any}) => {
    const newFilter: any = {};
    newFilter[filter.key] = filter.value;

    // Update filters state.
    setFilters({...filters, ...newFilter});

    // Then handle search params too.
    if (!isBoolean(filter.value) && isEmpty(filter.value)) {
      updateSearchParams([{...filter, ...{action: 'delete'}}]);
    } else {
      // Particular case : do not blow URL if all modules/types are selected !
      if (['types', 'modules'].includes(filter?.key ?? '') && ((filter?.value?.length ?? 0) - 1 === (modules?.length || types?.length))) {
        updateSearchParams([{...filter, ...{action: 'delete'}}]);
      } else {
        updateSearchParams([filter]);
      }
    }
  };

  const updateColumns = (columns: any[]) => {
    setColumns(columns);
    const params: IRequestParams = {
      resourceType: 'userContextualConfigs',
      notif: false,
      data: {
        user: userState.id(),
        key: 'ged_displayed_columns',
        context: {
          app: 'a8',
          component: 'ged_datatable'
        },
        metadata: columns
      },
    } as IRequestParams;

    if (isValidUUID(userConfigId)) {
      apim.patchEntity({...params, ...{id: userConfigId}});
    } else {
      apim.postEntity({...params, ...{success: (res: AxiosResponse) => setUserConfigId(res?.data?.id)}});
    }
  };

  // VBOs
  const selectedActions = [
    {
      label: ucfirst(trans(t, 'table|vbos')),
      icon: 'pi pi-ellipsis-v',
      items: [
        {
          label: ucfirst(trans(t, 'edit')),
          icon: 'pi pi-pencil',
          command: () => onRowEdit(),
        },
        {
          label: ucfirst(trans(t, 'delete')),
          icon: 'pi pi-trash',
          className: 'color-red',
          command: () => deleteSelected()
        },
      ]
    }
  ];

  const deleteSelected = () => dialog(t, {
    message: ucfirst(trans(t, 'table|deleteSelected')),
    accept: () => removeFiles(apim, formState, selected, parentKeyGed, setSelected),
    acceptClassName: 'p-button-danger',
    rejectClassName: 'p-button-text p-button-primary'
  });

  // Header
  const header = () => {
    let dossierFilterValues: any = [];
    (filters?.dossiers ?? []).forEach((dossier: any) => {
      if (isValidUUID(dossier)) dossierFilterValues.push(dossier);
      if (dossier === 'none') dossierFilterValues.push('none');
    });

    return <div>
      <div className={'flex flex-column md:flex-row md:flex-wrap md:justify-content-start md:align-items-center mb-4 gap-3'}>
        <Menu model={selectedActions} popup ref={vboMenuRef} id={'popup_vbos_menu'}/>
        <Button className={'block mt-2 md:mt-0'} type={'button'} disabled={(selected ?? []).length < 1} label={ucfirst(trans(t, 'action', 2))} icon={'pi pi-ellipsis-v'} onClick={(event) => vboMenuRef.current.toggle(event)} aria-controls={'popup_vbos_menu'} aria-haspopup/>

        <span className={'block mt-2 md:mt-0 p-input-icon-left'}>
          <i className={'pi pi-search'}/>
          <InputText type={'search'} onChange={onGlobalFilter} placeholder={trans(t, 'search')} defaultValue={lazyState?.filters?.global?.value}/>
        </span>

        {!dossier?.id && !isClient() && (
          <UncontrolledAPIAutocompleteField fieldKey={'dossier'} onFieldChange={(value: any) => updateFilters({key: 'dossiers', value: value ?? []})} label={trans(t, 'dossier', 2)} resourceType={'dossiersIndex'}
                                            placeholder={ucfirst(trans(t, 'form|select_dossier'))} optionKey={'name'} value={filters?.dossiers ?? []} itemTemplate={dossiersEditorItemTemplate} multiple
                                            formatter={(_arr: any[]) => [...[{name: ucfirst(trans(t, 'nrFull')), value: 'none'}], ..._arr]}
                                            params={[{label: 'order[active]', value: 'asc'}, {label: 'order[name]', value: 'asc'}, (company?.id ? {label: 'companies.id', value: company?.id} : {})]}/>
        )}

        {!(company?.id || (dossier?.id && appState.dossier()?.companies?.length === 1)) && (
          <UncontrolledAPIAutocompleteField fieldKey={'company'} onFieldChange={(value: any) => updateFilters({key: 'companies', value: value ?? []})} label={trans(t, 'company', 2)} multiple
                                            formatter={(_arr: any[]) => [...[{name: ucfirst(trans(t, 'nrFull')), value: 'none'}], ..._arr]}
                                            placeholder={ucfirst(trans(t, 'form|select_company'))} optionKey={'name'} value={filters?.companies ?? []} itemTemplate={companiesEditorItemTemplate}
                                            resourceType={'dossierCompaniesIndex'} params={[{label: 'order[name]', value: 'asc'}, (dossier?.id ? {label: 'dossiers.id', value: dossier?.id} : {})]}/>
        )}

        {modules?.length > 1 && (
          <UncontrolledStaticListField fieldKey={'module'} onFieldChange={(value: any) => updateFilters({key: 'modules', value: value ?? []})} label={trans(t, 'brique', 2)} resourceType={'modules'}
                                       placeholder={ucfirst(trans(t, 'form|select_brique'))} optionLabel={'label'} optionValue={'id'} value={filters?.modules ?? []} multiple
                                       listsOptions={{modules: [...[{label: ucfirst(trans(t, 'nrFull')), id: 'none'}], ...modules]}} listKey={'modules'} classes={'max-w-15rem'}/>
        )}

        {types?.length > 1 && (
          <UncontrolledStaticListField fieldKey={'type'} onFieldChange={(value: any) => updateFilters({key: 'types', value: value ?? []})} label={trans(t, 'type', 2)} resourceType={'documentTypes'}
                                       placeholder={ucfirst(trans(t, 'form|select_type'))} optionLabel={'name'} optionValue={'id'} value={filters?.types ?? []} multiple
                                       listsOptions={{types: [...[{name: ucfirst(trans(t, 'nrFull')), id: 'none'}], ...types]}} listKey={'types'} classes={'max-w-15rem'}/>
        )}

        {isAdmin() && (
          <UncontrolledTriStateField fieldKey={'active'} onFieldChange={(value: any) => updateFilters({key: 'active', value})} label={ucfirst(trans(t, 'active'))} value={filters?.active}/>
        )}
        <UncontrolledCheckboxField classes={'flex-auto'} fieldKey={'issued'} onFieldChange={(value: any) => updateFilters({key: 'issued', value})} label={ucfirst(trans(t, 'a_trier'))} value={filters?.issued}/>

        <UncontrolledStaticListField fieldKey={'columns'} onFieldChange={(value: any) => updateColumns(value)} label={ucfirst(trans(t, 'display'))} value={columns ?? []} multiple display={'comma'} maxSelectedLabels={3}
                                     placeholder={ucfirst(trans(t, 'column', 2))} listsOptions={{columns: allColumns}} listKey={'columns'} classes={'max-w-15rem'} filter={false}
                                     selectedItemsLabel={trans(t, 'table|selected_item', (columns ?? []).length)}/>

        <Button id={'clear-filters-ged'} className={'align-self-end mb-1'} type={'button'} severity={'info'} icon={'pi pi-filter-slash'} outlined onClick={resetDefault}/>
        <Tooltip target={'#clear-filters-ged'} position={'left'} content={ucfirst(trans(t, 'table|clearFilters'))}/>
      </div>

      {getFiles(formState, parentKeyGed).filter((f: any) => !f.complete).length > 0 && (
        <div className={'flex justify-content-center'}>
          <Message severity={'warn'} className={'px-5 py-2 mb-2 rounded-md w-full'} text={ucfirst(trans(t, 'ged_issued_documents_text'))}/>
        </div>
      )}
    </div>;
  };

  const isSelectable = (data: any) => (data.status !== 'uploading' && !data.deleting);
  const isRowSelectable = (event: any) => (event.data ? isSelectable(event.data) : true);
  const rowClassName = (data: any) => (isSelectable(data) ? 'un-highlight' : 'un-highlight p-disabled');

  const renderForm = useCallback(() => <FormWrapper classes={'grid p-fluid w-12'} resourceType={'documents'} formKeyPrefix={'documents_dialog'}
                                                    data={isValidUUID(editModalIndex) ? getFile(formState, editModalIndex, parentKeyGed) : null}
                                                    hideReload onSubmit={(submittedData: any) => onSubmit(submittedData)} loading={calling}
                                                    context={{dossier, company, types, companiesEditorItemTemplate, dossiersEditorItemTemplate}}/>
  , [editModalIndex, selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowEdit = (document: any = null) => {
    if (editModalIndex !== document?.id) {
      setEditModalIndex(document?.id ?? '');
    }

    setDocumentPreview(null);
    if (isValidUUID(document?.id)) {
      loadPreview(document);
    }

    if (!editModalVisible) {
      setEditModalVisible(true);
      setEditModalLoading(false);
    }
  };

  const onRowView = (document: any = null) => {
    navigate(appUri.doc.page.replace(':id', document?.id || '_'));
  };

  const onSubmit = (formData: any) => {
    setCalling(true);
    setEditModalLoading(true);

    const patchParams = {
      resourceType: 'documents',
      error: () => setCalling(false)
    } as IRequestParams;

    let _data: any = { data: pick(formData, ['date', 'description', 'title']) };

    // Ids to Iris.
    if (isValidUUID(formData?.type)) {
      _data.data.type = iri('documentTypes', formData?.type);
    }

    if (isArray(formData?.companies)) {
      if ((formData?.companies ?? []).length > 0) {
        const _c: string[] = [];
        forEach(formData?.companies ?? [], (c: string) => {
          if (isValidUUID(c)) _c.push(iri('dossier_companies', c));
        });

        if (_c.length > 0) _data.data.companies = _c;
      } else {
        _data.data.companies = [];
      }
    }

    if (isArray(formData?.dossiers)) {
      if ((formData?.dossiers ?? []).length > 0) {
        const _d: string[] = [];
        forEach(formData?.dossiers ?? [], (d: string) => {
          if (isValidUUID(d)) _d.push(iri('dossiers', d));
        });

        if (_d.length > 0) _data.data.dossiers = _d;
      } else {
        _data.data.dossiers = [];
      }
    }

    // Adjust things using multiple or single edit mode.
    if (!isValidUUID(editModalIndex)) {
      patchParams.action = 'multipleEdit';

      const _s: string[] = [];
      forEach(selected ?? [], (s: any) => {
        if (isValidUUID(s?.id)) _s.push(iri('documents', s.id));
      });

      if (_s.length > 0) _data.documents = _s;
      if (formData?.active !== undefined) _data.data.active = formData?.active !== false;

      patchParams.data = _data;
    } else {
      patchParams.id = editModalIndex;
      _data.data.active = formData?.active !== false;

      patchParams.data = _data?.data;
    }

    patchParams.success = (res: AxiosResponse) => {
      // Update datatable rows using patched data then res data.
      const rowsToUpdate: any[] = patchParams.action !== 'multipleEdit' ? [getFile(formState, editModalIndex, parentKeyGed)] : selected;
      forEach(rowsToUpdate, (_r: any) => {
        let updated: any = null;

        // Adjust things using multiple or single edit mode.
        if (isValidUUID(editModalIndex)) {
          // Single mode.
          updated = {...omit(_r, ['companies', 'dossiers']), ...omit(_data?.data ?? _data, ['companies', 'dossiers']), ...pick(res?.data, ['companies', 'dossiers', 'complete'])};
          if (isValidUUID(res?.data?.type?.id)) {
            updated.type = types.find((type: any) => type?.id === res?.data?.type?.id);
          }
        } else {
          // Multiple mode.
          const resDataFiltered: any = res?.data?.documents?.filter((doc: any) => doc.id === _r.id);
          const resDocument: any = resDataFiltered[0] ?? null;
          updated = {...omit(_r, ['companies', 'dossiers']), ...omit(_data?.data ?? _data, ['companies', 'dossiers']), ...pick(resDocument, ['companies', 'dossiers', 'complete'])};
          if (isValidUUID(resDocument?.type?.id)) {
            updated.type = types.find((type: any) => type?.id === resDocument?.type?.id);
          }
        }

        updateFile(formState, updated, parentKeyGed);

        // Check if we need to remove the file from the datatable.
        // If the "issued" filter is active & document is complete.
        if (updated.complete && filters?.issued) {
          removeFile(apim, formState, updated, parentKeyGed, false);
        }
      });

      setCalling(false);
      setEditModalVisible(false);
    };

    patchParams.error = (res: AxiosResponse) => {
      setCalling(false);
      setEditModalVisible(true);
      setEditModalLoading(false);
    };

    patchParams.action !== 'multipleEdit' ?
      apim.patchEntity(patchParams) :
      apim.call(patchParams);
  }

  const actionsBodyTemplate = (rowData: any) => {
    const items = [{
      label: ucfirst(trans(t, 'view')),
      icon: 'pi pi-eye',
      className: 'bg-indigo-500',
      command: () => onRowView(rowData)
    }, {
      label: ucfirst(trans(t, 'edit')),
      icon: 'pi pi-pencil',
      className: 'bg-indigo-500',
      command: () => onRowEdit(rowData)
    }, {
      label: ucfirst(trans(t, 'system|download')),
      icon: 'pi pi-cloud-download',
      className: 'bg-indigo-500',
      command: () => download(rowData)
    }];

    if (isAdmin()) {
      items.push({
        label: rowData?.active ? ucfirst(trans(t, 'system|actions.disable')) : ucfirst(trans(t, 'system|actions.enable')),
        icon: rowData?.active ? 'pi pi-ban' : 'pi pi-check',
        className: 'bg-orange-500',
        command: () => {
          if (rowData?.id) {
            const active = !rowData.active;
            const action = active ? 'enable' : 'disable';

            apim.patchEntity({
              resourceType: 'documents',
              id: rowData.id,
              data: {active: active},
              notifSuccess: {
                summary: trans(t, 'notification|document.' + action + '.summary'),
                details: trans(t, 'notification|document.' + action + '.details'),
              },
              success: () => updateFile(formState, {...rowData, ...{active}}, parentKeyGed),
            } as IRequestParams);
          }
        }
      });

      if (rowData.deleted === null || rowData.deleted === undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.archive')),
          icon: 'pi pi-folder',
          className:'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.document.archive')),
              accept: () => {
                if (rowData?.id) {
                  const deleted = new Date();
                  apim.patchEntity({
                    resourceType: 'documents',
                    id: rowData?.id,
                    data: {
                      active: false,
                      deleted: deleted
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|document.archive.summary'),
                      details: trans(t, 'notification|document.archive.details'),
                    },
                    success: () => {
                      if (isSuperAdmin()) {
                        updateFile(formState, {...rowData, ...{deleted, active: false}}, parentKeyGed)
                      } else {
                        removeFile(apim, formState, rowData, parentKeyGed, false);
                      }
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }

      if (rowData.deleted !== null && rowData.deleted !== undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.unarchive')),
          icon: 'pi pi-folder-open',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.document.unarchive')),
              accept: () => {
                if (rowData?.id) {
                  apim.patchEntity({
                    resourceType: 'documents',
                    id: rowData?.id,
                    data: {
                      active: true,
                      deleted: null
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|document.unarchive.summary'),
                      details: trans(t, 'notification|document.unarchive.details'),
                    },
                    success: () => updateFile(formState, {...rowData, ...{
                        deleted: null,
                        active: true
                      }}, parentKeyGed),
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    items.push({
      label: ucfirst(trans(t, 'system|actions.delete')),
      icon: 'pi pi-trash',
      className:'bg-red-500',
      command: () => {
        dialog(t, {
          message: ucfirst(trans(t, 'system|confirmations.document.delete')),
          accept: () => removeFile(apim, formState, rowData, parentKeyGed),
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-text p-button-primary'
        });
      }
    });

    return <>
      <Tooltip target={'.a8-speedial-datatable .p-speeddial-action'} position={'top'} mouseTrack/>
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40} showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'}/>
    </>
  };

  const headerTemplate = (options: any) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div>
          <div className='font-bold text-lg'>{trans(t, 'fileUploadTitle')}</div>
          <p>{trans(t, 'fileUploadDescription')}</p>
        </div>
        <div>
          {options.togglerElement}
        </div>
      </div>
    );
  };

  return (
    <div className={'card fadein animation-duration-500'}>
      <Panel headerTemplate={headerTemplate} toggleable collapsed={true} className={'mb-4'}>
        <div className={'grid p-fluid'}>
          {!formState.isLoading(formKey) ? (
            <>
              <div className={'col-12'}>
                <div className={'col-12 d-flex justify-content-center align-items-center'}>
                  {droppedFiles()}
                </div>

                <div className={'dropzone'}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div className={'dz-message bgh-primary-lightest animated-non-stop'}>
                      <img alt={'upload'} className={'dz-teaser-img'} src={cloudFile as string}/>

                      <div className={'dx-text'}>
                        {!isDragActive && trans(t, 'system|dropzoneMessageDefault')}
                        {isDragActive && trans(t, 'system|dropzoneMessageActive')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Loader text={trans(t, 'system|loading')}/>
          )}
        </div>
      </Panel>

      <div className={'grid fadein animation-duration-500'}>
        <div className={'col-12'}>
          <div className={'card'}>
            <DataTable
              header={header} stripedRows value={getFiles(formState, parentKeyGed, false, false) as any} lazy dataKey={'id'} loading={loading} className={'datatable-responsive'}
              paginator first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecords} rowsPerPageOptions={appConfig.pagination.default.rowsPerPageOptions}
              paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'}
              currentPageReportTemplate={trans(t, 'result', (totalRecords ?? 1)) + ' {first} à {last} (sur ' + (totalRecords ?? 0) + ')'} emptyMessage={trans(t, 'document', 0)}
              onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder} filterDelay={300}
              globalFilterFields={['filename', 'extension', 'dossiers.title', 'companies.name', 'type.name', 'date', 'active']}
              selectionMode={'checkbox'} selection={selected} onSelectionChange={(e: any) => setSelected(e.value)} isDataSelectable={isRowSelectable} rowClassName={rowClassName}>

              <Column selectionMode={'multiple'} headerStyle={{ width: '3rem' }}/>
              <Column field={'type'} sortField={'type.name'} header={trans(t, 'file')} sortable body={labelBodyTemplate} style={{ maxWidth: '250px', width: '250px' }}/>

              {(columns ?? []).includes('date') && (
                <Column field={'date'} header={trans(t, 'date')} sortable body={dateBodyTemplate} align={'center'} alignHeader={'center'} style={{ width: '120px', minWidth: '120px' }}/>
              )}

              {(columns ?? []).includes('description') && (
                <Column field={'description'} header={trans(t, 'description')} body={descriptionBodyTemplate} />
              )}

              {(columns ?? []).includes('extension') && (
                <Column field={'extension'} header={trans(t, 'extensionShort')} sortable align={'center'} style={{ width: '100px' }}/>
              )}

              {(columns ?? []).includes('size') && (
                <Column field={'size'} header={trans(t, 'size')} align={'center'} sortable body={sizeBodyTemplate} style={{ width: '100px' }} />
              )}

              {isAdmin() && (columns ?? []).includes('filename') && (
                <Column field={'filename'} header={trans(t, 'filename')} sortable align={'center'} body={filenameBodyTemplate} style={{ width: '100px', maxWidth: '100px' }}/>
              )}

              {(columns ?? []).includes('created') && (
                <Column field={'created'} header={trans(t, 'upload_date')} sortable align={'center'} body={createdBodyTemplate} style={{ width: '225px' }}/>
              )}

              {(columns ?? []).includes('changed') && (
                <Column field={'updated'} header={trans(t, 'changed_date')} sortable align={'center'} body={updatedBodyTemplate} style={{ width: '225px' }}/>
              )}

              {isAdmin() && (columns ?? []).includes('deleted') && (
                <Column field={'deleted'} header={trans(t, 'deleted_date')} sortable align={'center'} body={deletedBodyTemplate} style={{ width: '225px' }}/>
              )}

              {(columns ?? []).includes('user') && (
                <Column field={'author.email'} header={trans(t, 'author')} sortable align={'left'} body={userBodyTemplate} style={{ width: '225px' }}/>
              )}

              {!isClient() && (columns ?? []).includes('dossiers') && (
                <Column field={'dossiers'} header={trans(t, 'dossier', 2)} body={dossierBodyTemplate} style={{ width: '250px' }}/>
              )}

              {(columns ?? []).includes('companies') && (
                <Column field={'companies'} header={trans(t, 'company', 2)} body={companyBodyTemplate} style={{ width: '250px' }}/>
              )}

              {(columns ?? []).includes('source') && (
                <Column field={'source'} header={trans(t, 'source')} sortable align={'center'} body={sourceBodyTemplate}/>
              )}

              {(columns ?? []).includes('fileStatus') && (
                <Column field={'fileStatus'} header={trans(t, 'fileStatus')} sortable align={'center'} body={fileStatusBodyTemplate}/>
              )}

              {isAdmin() && (columns ?? []).includes('active') && (
                <Column field={'active'} header={trans(t, 'active')} sortable dataType={'boolean'} align={'center'} body={activeBodyTemplate}/>
              )}

              <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate} style={{width: '80px', maxWidth: '80px'}}/>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog content={''} ref={modalRef} header={ucfirst(trans(t, 'file_preview'))} visible={modalVisible} style={{ width: '80vw', height: '100%' }} onHide={() => setModalVisible(false)}>
        <DocumentViewer documentPreview={documentPreview} viewerRef={viewerRef}/>
      </Dialog>

      <Dialog content={''} ref={editModalRef} visible={editModalVisible} onHide={() => setEditModalVisible(false)} className={documentPreview !== null ? 'w-12 md:w-10 lg:w-8 xl:w-6 xxl:w-8' : 'w-12 md:w-10 lg:w-10 xl:w-7 xxl:w-5'}
              header={ucfirst(trans(t, isValidUUID(editModalIndex) ? 'system|confirmations.document.edit' : 'system|confirmations.documents_selected.edit'))}>
        {editModalLoading ? (
          <div className={'mb-8'}>
            <PageLoader />
          </div>
        ) : (
          <div className={'flex xxl:flex-row'}>
            {documentPreview !== null && (
              <div className={'hidden xxl:flex'} style={{width: '100%', height: '550px', overflowY: 'auto'}}>
                <DocumentViewer documentPreview={documentPreview} viewerRef={viewerRef}/>
              </div>
            )}
            {renderForm()}
          </div>
        )}
      </Dialog>
    </div>
  );
};
