import React, { useEffect } from 'react';

import { addShortcut, trans } from 'utilities';
import { isAdmin, isClient, isExpert, useApim } from 'services';
import { DossiersDatatable } from 'components';
import { useAppState, useFormState, useUserState } from 'states';
import { Unauthorized } from 'pages';

import appUri from 'config/appUri.json';

export const DossiersList = () => {
  const apim = useApim();
  const { t } = apim.di();
  const appState = useAppState();
  const formState = useFormState();
  const userState = useUserState();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossiers'));
    appState.setBreadcrumb([{ label: trans(t, 'dossier', 2) }]);

    const newPA: any[] = [{ label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
    ];
    if (isExpert() || isAdmin()) {
      newPA.push({ label: trans(t, 'dos.add'), icon: 'plus', to: appUri.dos.add, className:'bg-green-500' });
    }
    appState.setPageActions(newPA);
  }, [userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  return isClient() ?
    <Unauthorized asBlock/> :
    <DossiersDatatable appState={appState} tableKey={'dossiers'} noFilters title={trans(t, 'menu|pages.title.dossiers')} apim={apim}/>
  ;
};
