// React & vendors.
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { Worker } from '@react-pdf-viewer/core';

// Primereact.
import { classNames } from 'primereact/utils';
import PrimeReact from 'primereact/api';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ScrollTop } from 'primereact/scrolltop';
import { BlockUI } from 'primereact/blockui';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.scss';

// App.
import { AppTopbar, AppSubHeader, AppFooter, AppMenu, AppRightMenu, AppUserMenu } from 'layouts';
import { useThemeState, useSearchState, useAppState, useUserState, useNotificationState } from 'states';
import { useApim, IRequestParams, subscribe, userId } from 'services';
import { trans } from 'utilities';
import { TicketCollector } from 'pages';
import { IdleManager } from 'components';

import appUri from 'config/appUri.json';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useRef<any>();
  const toastError = useRef<any>();
  const toastSuccess = useRef<any>();

  const [checked, setChecked] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);

  const { t } = useTranslation();
  const appState = useAppState();
  const searchState = useSearchState();
  const themeState = useThemeState();
  const userState = useUserState();
  const notificationState = useNotificationState();
  const apim = useApim();

  // Avoid ending slash.
  useEffect(() => {
    if (location.pathname.length > 1 && location.pathname.slice(-1) === '/') {
      navigate(location.pathname.substring(0, location.pathname.length - 1));
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // Early Dependency injection !
  useEffect(() => {
    if (!themeState.toast()) {
      themeState.setToast(toast);
      themeState.setToast(toastError, 'error');
      themeState.setToast(toastSuccess, 'success');
    }

    if (!apim.toast()) {
      apim.setToast(toast);
      apim.setToast(toastError, 'error');
      apim.setToast(toastSuccess, 'success');
    }

    if (!apim.t()) {
      apim.setT(() => t);
    }

    if (!apim.navigate()) {
      apim.setNavigate(() => navigate);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // User login + system checks.
  useEffect(() => {
    let healthCheckInterval: any = null;

    // Check the login status (using local storage).
    userState.checkAuth(() => setAuthorized(true), userState, () => setChecked(true));

    // Check the maintenance status.
    apim.call({
      resourceType: 'healthcheck',
      action: 'status',
      method: 'get',
      cache: false,
      notif: false,
      success: (res: AxiosResponse) => apim.setMaintenance(!res?.data?.success),
      error: (err: any) => {
        const responseStatus = err?.response?.status;
        if (responseStatus === 200) return apim.setMaintenance(false);
        else if (responseStatus === 503 || responseStatus === 502) return apim.setMaintenance(true);

        // In any other situation, show the maintenance as well.
        // console.error('Can not health check the API: '+err);
        apim.setMaintenance(true);

        // Use a regular poll on the API healthcheck.
        // Because APIM might be under deployment.
        healthCheckInterval = setInterval(() => {
          if (!userId()) return;

          apim.call({
            resourceType: 'healthcheck',
            action: 'status',
            method: 'get',
            cache: false,
            notif: false,
            auth: false,
            success: (res: AxiosResponse) => {
              if (res?.data?.success) {
                apim.setMaintenance(false);
                if (healthCheckInterval !== null) clearInterval(healthCheckInterval);
              }
            },
          } as IRequestParams).then();
        }, 5000);
      }
    } as IRequestParams).then();

    // Clear the interval when unmounting the component.
    return () => {
      if (healthCheckInterval !== null) clearInterval(healthCheckInterval);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Force login redirection if user is disconnected,
  // but let's keep the current path in order to redirect the user after a login success.
  useEffect(() => {
    if (checked && !authorized) {
      navigate(appUri.sys.login);
    }
  }, [checked, authorized]); // eslint-disable-line react-hooks/exhaustive-deps

  // System management.
  useEffect(() => {
    subscribe(['/system'], (systemMessage: any) => {
      if (!systemMessage.data) return;

      // Determine what action was performed by the system.
      const systemMessageData = JSON.parse(systemMessage.data);
      const systemMessageSystem = systemMessageData['system'] ?? null;
      const systemMessageAction = systemMessageData['action'] ?? null;
      if (systemMessageSystem === null || systemMessageAction === null) return;

      // Maintenance switch ON/OFF.
      if (systemMessageAction === 'maintenance') {
        const systemMessageStatus = systemMessageData['status'] ?? null;
        if (systemMessageStatus === null) return;

        // Set the maintenance status in system state.
        apim.setMaintenance(systemMessageStatus);
      }
    })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Modules management.
  useEffect(() => {
    if (!userId()) return;

    apim.fetchEntities({
      resourceType: 'modules',
      params: [
        {label: 'order[label]', value: 'asc'},
        {label: 'itemsPerPage', value: 500}
      ],
      setter: appState.setModules,
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Reset current module during navigation.
  useEffect(() => {
    if (!location.pathname.includes('/dossier/')) appState.setModule(null);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // Notifications management.
  useEffect(() => {
    if (!userId()) return;

    // Initially check unread notifications for current logged user.
    apim.fetchEntities({
      resourceType: 'notifications',
      params: [{label: 'user', value: userId()}, {label: 'read', value: false}],
      notif: false,
      success: (res: AxiosResponse) => {
        if (!res?.data) return;

        notificationState.setCountUnread(res.data['hydra:totalItems'] ?? 0);
      }
    } as IRequestParams).then();

    // Initially check undone notifications for current logged user.
    apim.fetchEntities({
      resourceType: 'notifications',
      params: [{label: 'user', value: userId()}, {label: 'done', value: false}],
      notif: false,
      success: (res: AxiosResponse) => {
        if (!res?.data) return;

        notificationState.setCount(res.data['hydra:totalItems'] ?? 0);
      }
    } as IRequestParams).then();

    // Subscribe to the user topic to get all new notifications.
    notificationState.subscribe(userState, themeState);
  }, [userId()]); // eslint-disable-line react-hooks/exhaustive-deps

  PrimeReact.ripple = true;
  const isDesktop = themeState.isDesktop();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Update document (page/tab) title.
  useEffect(() => {
    document.title = appState?.pageTitle() ? 'Atome 8 - ' + appState.pageTitle() : 'Atome 8';
  }, [appState?.pageTitle()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's close everything opened.
  const onDocumentClick = (event: any) => {
    if (searchState.isToggle() && (undefined === event.target.id || event.target.id.substring(0, 7) !== 'search-')) {
      searchState.toggle();
    }

    if (!themeState.isTopbarItemClicked()) {
      themeState.state().activeTopbarItem.set(null);
    }
    if (!themeState.isMenuClicked() && !isDesktop) {
      themeState.toggleMobileMenu(false);
      themeState.toggleDesktopMenu(false);
    }
    themeState.toggleTopbarItemClick(false);
    themeState.toggleInlineMenuClick(false);
    themeState.toggleMenuClick(false);
  };

  // Toggle Sidebar User Menu.
  const onInlineMenuClick = () => {
    themeState.toggleInlineMenu();
    themeState.toggleInlineMenuClick(true);
  };

  const layoutContainerClassName = classNames('layout-wrapper layout-menu-indigo layout-topbar-white', {
    'layout-menu-static': themeState.menuMode() === 'static',
    'layout-menu-slim': themeState.menuMode() === 'slim',
    'layout-menu-active': themeState.isDesktopMenuActive(),
    'layout-menu-mobile-active': themeState.isMobileMenuActive(),
    'layout-topbar-mobile-active': themeState.isMobileTopbarActive(),
    'layout-rightmenu-active': themeState.isRightMenuActive()
  });

  const maintenanceBlockUiTemplate = () => {
    return <Card className={'p-6 border-round-xl'}>
        <div className={'flex flex-column justify-content-center align-items-center'}>
          <i className='pi pi-wrench' style={{ fontSize: '5rem' }}></i>
          <h2>{trans(t, 'system|maintenance.title')}</h2>
          <ProgressBar className={'mb-5'} mode='indeterminate' style={{ height: '2px', width: '290px' }}></ProgressBar>
          <p className={'text-center'}>
            {trans(t, 'system|maintenance.message')}<br/>
            {trans(t, 'system|maintenance.message2')}<br/>
            {trans(t, 'system|maintenance.team')}
          </p>
        </div>
      </Card>
  }

  return (
    <div className={layoutContainerClassName} onClick={onDocumentClick}>
      <Toast ref={toast} content={''}/>
      <Toast ref={toastError} position={'top-center'} content={''}/>
      <Toast ref={toastSuccess} position={'top-center'} content={''}/>
      <ConfirmDialog content={''}/>
      <IdleManager apim={apim}/>

      {checked && (
        <>
          <BlockUI blocked={apim.maintenance()} fullScreen template={maintenanceBlockUiTemplate}>
            <AppTopbar apim={apim} searchState={searchState} appState={appState} userState={userState} themeState={themeState}/>

            <div className={'menu-wrapper'} onClick={() => themeState.toggleMenuClick(true)}>
              <div className={'layout-menu-container'}>
                <AppUserMenu
                  apim={apim}
                  menuKey={'top'}
                  onInlineMenuClick={onInlineMenuClick}
                  horizontal={false}
                  menuMode={themeState.menuMode()}
                  themeState={themeState}
                  userState={userState}
                />
                <AppMenu apim={apim} themeState={themeState} notificationState={notificationState} userState={userState} appState={appState} />
              </div>
            </div>

            <div className={'layout-main'}>
              <AppSubHeader apim={apim} appState={appState} userState={userState}/>

              <div className={'layout-content'}>
                <Worker workerUrl={'/assets/libs/pdf-worker/pdf.worker.min.js'}>
                  <Outlet/>
                </Worker>
              </div>

              <AppFooter/>
            </div>

            <AppRightMenu apim={apim} themeState={themeState} userState={userState}/>
            {themeState.isMobileMenuActive() && <div className={'layout-mask modal-in'}></div>}

            <TicketCollector/>
            <ScrollTop/>
          </BlockUI>
        </>
      )}
    </div>
  );
};

export default App;
