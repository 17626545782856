import React, { useCallback, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';

import { dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { birthDateBody, DatatableWrapper, dateTimeCell, validCell } from 'components';
import { IRequestParams, isAdmin, isClient, isExpertAdmin } from 'services';
import { Unauthorized } from 'pages';
import { FormWrapper, MergePersonnesPhysiquesForm } from 'forms';

import appUri from 'config/appUri.json';

export const PersonnesPhysiquesDatatable = (props: any) => {
  const { apim } = props;
  const { t, navigate } = apim.di();
  const [selectedPersonnesPhysiques, setSelectedPersonnesPhysiques] = useState<any[]>([]);
  const [main, setMain] = useState<string|null>(null);
  const [disabledItems, setDisabledItems] = useState<any[]>([]);

  const lazyC = {
      sortField: 'lastName',
      sortOrder: 1,
  };

  let params = [
    { label: 'expand[]', value: 'personne_physique:read_dossier' },
    { label: 'expand[]', value: 'dossier:read_minimal' },
  ];

  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const deletedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.deleted);

  const dossierBodyTemplate = (rowData: any) => {
    const dossier = rowData.dossier;
    if (dossier !== undefined && dossier !== null) {
      return <Link className={'block mt-1 text-sm'} to={appUri.dos.page.replace(':id', dossier.id)}>{dossier.name}</Link>;
    }

    return null;
  };

  const actionsBodyTemplate = (rowData: any) => {
    let items = [];

    if (isAdmin() || isExpertAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.edit')),
        icon: 'pi pi-pencil',
        className: 'bg-indigo-500',
        command: () => {
          navigate(appUri.cie.per.phy.edit.replace(':id', rowData?.id || '_'));
        }
      });
    }

    if (isAdmin()) {
      items.push({
        label: rowData?.active ? ucfirst(trans(t, 'system|actions.disable')) : ucfirst(trans(t, 'system|actions.enable')),
        icon: rowData?.active ? 'pi pi-ban' : 'pi pi-check',
        className: 'bg-orange-500',
        command: () => {
          if (rowData?.id) {
            const active= !rowData.active;
            const action= active ? 'enable' : 'disable';

            apim.patchEntity({
              resourceType: 'personnesPhysiques',
              id: rowData.id,
              data: { active: active },
              notifSuccess: {
                summary: trans(t, 'notification|personnePhysique.'+action+'.summary'),
                details: trans(t, 'notification|personnePhysique.'+action+'.details'),
              },
              success: () => {
                rowData.active = active;
              },
            } as IRequestParams);
          }
        }
      });

      if (rowData.deleted === null || rowData.deleted === undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.archive')),
          icon: 'pi pi-folder',
          className:'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.personnePhysique.archive')),
              accept: () => {
                if (rowData?.id) {
                  const deleted = new Date();
                  apim.patchEntity({
                    resourceType: 'personnesPhysiques',
                    id: rowData?.id,
                    data: {
                      active: false,
                      deleted: deleted
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|personnePhysique.archive.summary'),
                      details: trans(t, 'notification|personnePhysique.archive.details')
                    },
                    success: () => {
                      rowData.deleted = deleted;

                      const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                      _disabledItems.push(rowData.id);
                      setDisabledItems(_disabledItems);
                    }
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    if (isAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.delete')),
        icon: 'pi pi-trash',
        className:'bg-red-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.personnePhysique.delete')),
            accept: () => {
              if (rowData?.id) {
                apim.deleteEntity({
                  resourceType: 'personnesPhysiques',
                  id: rowData?.id,
                  success: () => {
                    const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                    _disabledItems.push(rowData.id);
                    setDisabledItems(_disabledItems);
                  },
                } as IRequestParams);
              }
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }
      });

      if (rowData.deleted !== null && rowData.deleted !== undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.unarchive')),
          icon: 'pi pi-folder-open',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.personnePhysique.unarchive')),
              accept: () => {
                if (rowData?.id) {
                  apim.patchEntity({
                    resourceType: 'personnesPhysiques',
                    id: rowData?.id,
                    data: {
                      active: true,
                      deleted: null
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|personnePhysique.unarchive.summary'),
                      details: trans(t, 'notification|personnePhysique.unarchive.details'),
                    },
                    success: () => {
                      rowData.deleted = null;
                      rowData.active = true;
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    return (
      <>
        <Tooltip target={'.a8-speedial-datatable .p-speeddial-action'} position={'top'} mouseTrack/>
        <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40} showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'}/>
      </>
    );
  };

  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={'personnesPhysiques'} formKeyPrefix={'add_pp_dialog'} cancelLink multiple
                   resetClass={'a8-form-pp-reset'} submitClass={'a8-form-pp-add'} hideReload context={{onlyNew: true}}/>
    , []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|company.personnePhysiqueAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-pp-add'),
      reject: () => triggerFormReset('a8-form-pp-reset')
    });
  };

  const onMerge = () => {
    if (isValidUUID(main) && selectedPersonnesPhysiques?.length > 1) {
      apim.call({
        resourceType: 'personnesPhysiques',
        action: 'merge',
        notif: true,
        notifSuccess: {
          summary: trans(t, 'notification|personnePhysique.merge.summary'),
          details: trans(t, 'notification|personnePhysique.merge.details'),
        },
        data: {
          main: iri('personnesPhysiques', main ?? ''),
          merge: selectedPersonnesPhysiques.map((p: any) => iri('personnesPhysiques', p.id ?? ''))
        },
        success: () => navigate(appUri.home)
      } as IRequestParams).then();
    } else {
      apim.toast('error').current.show({
        severity: 'error',
        summary: trans(t, 'notification|personnePhysique.mergeError.summary'),
        detail: trans(t, 'notification|personnePhysique.mergeError.details'),
      });
    }
  };

  const mergePersonnesPhysiquesForm = useCallback(() =>
      <MergePersonnesPhysiquesForm key={main} apim={apim} persons={selectedPersonnesPhysiques} setMain={setMain} main={main} />
    , [selectedPersonnesPhysiques, main,setMain]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
  }, [main]);

  const mergePersonnesPhysiquesBtn = () => (
    <Button label={trans(t, 'mergePersonnesPhysiques')} icon={'pi pi-users'} onClick={() => {
      dialog(t, { header: ucfirst(trans(t, 'mergePersonnesPhysiques')),
          message: () => mergePersonnesPhysiquesForm(),
          icon: 'none',
          accept: () => onMerge()
        });
      }}
      disabled={selectedPersonnesPhysiques?.length < 2}
      rounded
    />
  );


  if (!isAdmin()) return <Unauthorized asBlock/>;

  return (
    <DatatableWrapper
      resourceType={'personnesPhysiques'} tableKey={'personnesPhysiques'} lazyConfig={lazyC} rowUri={appUri.cie.per.phy.page} noFilters={true} params={params} headerCreateBtn={isAdmin() ? mergePersonnesPhysiquesBtn : null}
      title={trans(t, 'menu|pages.title.user.personnes')} disabledItems={disabledItems} onNew={onNew} addTitle={trans(t, 'pers.add')} selection={selectedPersonnesPhysiques} onSelectionChange={(e: any) => setSelectedPersonnesPhysiques(e.value)}>
      {!isClient() && <Column selectionMode={'multiple'} headerStyle={{ width: '3rem' }}/>}
      <Column field={'firstName'} header={trans(t, 'firstName')} sortable style={{ width: '200px' }}/>
      <Column field={'lastName'} header={trans(t, 'lastName')} sortable style={{ width: '200px' }}/>
      <Column field={'birthName'} header={trans(t, 'birthName')} sortable style={{ minWidth: '100px', width: '100px' }}/>
      <Column field={'birthDate'} header={trans(t, 'birthDate')} sortable align={'center'} alignHeader={'center'} body={birthDateBody} style={{ minWidth: '100px', width: '100px' }}/>
      <Column field={'dossier.title'} header={trans(t, 'dossier')} sortable style={{ width: '200px' }} body={dossierBodyTemplate}/>
      <Column field={'email'} header={trans(t, 'email')} sortable style={{ width: '300px' }}/>
      <Column field={'phone1'} header={trans(t, 'phone1')} sortable style={{ width: '200px' }}/>
      <Column field={'phone2'} header={trans(t, 'phone2')} sortable style={{ width: '200px' }}/>
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate} style={{ width: '225px' }}/>
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate} style={{ width: '225px' }}/>
      <Column field={'deleted'} header={trans(t, 'deleted')} sortable align={'center'} body={deletedBodyTemplate} style={{ width: '100px' }}/>
      <Column field={'active'} header={trans(t, 'active')} sortable dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{ width: '100px' }}/>
      <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate} style={{ width: '80px', maxWidth: '80px' }}/>
    </DatatableWrapper>
  );
};
