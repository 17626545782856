import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { PageLoader } from 'components';
import { IRequestParams } from 'services';
import { useAppState } from 'states';
import { UserProfile } from './tabs';

import { replace, isEmpty } from 'lodash';
import appUri from 'config/appUri.json';

export const UserFormRouter = (props: any) => {
  const { apim, data } = props;
  const appState = useAppState()
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const { t, navigate } = apim.di();
  // If page is loaded without going through UserRouter first, get user id from URL
  const userId = isValidUUID(data?.id) ? data.id : pathParts[2];

  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(data);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const lastUpdate = searchParams.get('maj');
  useEffect(() => {
    if (!userData && isValidUUID(userId) ) {
      setLoading(true);
      apim.fetchEntity({
        resourceType: 'users',
        id: userId,
        cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
        setter: setUserData,
        setErrored,
        setLoading,
        setNotFound
      } as IRequestParams).then();
    }

  }, [userId, lastUpdate, userData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle((data ? ucfirst(trans(t, 'profile')) + ' ' + (userData.firstName + ' ' + userData.lastName) : '') + ' | ' + trans(t, 'editing'));
    appState.setPageActions([]);
    appState.setBreadcrumb([
      { label: ((userData ? userData.firstName + ' ' + userData.lastName : trans(t, 'user'))), to: replace(appUri.usr.page, ':id', userData?.id || '_') + '?maj=' + Date.now() }
    ]);

  }, [userData, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      {userData && <UserProfile data={userData} apim={apim} />}
    </div>
  );
};
