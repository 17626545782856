import React, { useEffect, useState } from 'react';

import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';
import { Skeleton } from 'primereact/skeleton';

import { dateString, formatListItem, shortNumber, cssClassName, trans, ucfirst } from 'utilities';
import { useApim, IRequestParams } from 'services';

import { forEach } from 'lodash';

export const IndicatorSimple = (props: any) => {
  const apim = useApim();
  const { t } = apim.di();

  let method = props.method;
  let options = props.options;

  const methodParams = props.methodParams || {listKey: null};
  const value: any = props.value;
  const text: any = props.text;
  const emptyValue = props.emptyValue || 'NR';

  // Symbol prop.
  const symbol = props.symbol ?? null;
  if (symbol !== null) {
    options = {...{symbol: symbol}, ...options};
  }

  // ExactValue prop.
  const exactValue = props.exactValue ?? null;
  if (exactValue !== null) {
    options = {...{exactValue: exactValue}, ...options};
  }

  // Precision prop.
  const precision = props.precision ?? null;
  if (precision !== null) {
    options = {...{precision: precision}, ...options};
  }

  const percentPrecision = props.percentPrecision ?? 1;

  // For list related indicators, load the list values.
  const [listItems, setListItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (value !== null && method === 'list' && methodParams.listKey) {
      setLoading(true);
      apim.getList(methodParams.listKey, {setter: setListItems, setLoading: setLoading} as IRequestParams).then();

    } else if (value !== null && method === 'country') {
      apim.fetchEntities({
        resourceType: 'countries',
        params: [{ label: 'order[name]', value: 'asc' }, { label: 'itemsPerPage', value: 500 }, {
          label: 'active',
          value: true
        }],
        setter: setListItems,
        setLoading: setLoading
      } as IRequestParams).then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let displayValue: any = '';
  if (text !== undefined && text !== null) {
    // Display given text.
    displayValue = text;
  } else {
    // Determine the method according to the value.
    if (!method) {
      if (value instanceof Date) {
        method = 'date';
      } else {
        method = 'montant';
      }
    }

    // Format the value according to the method.
    if (method === 'montant') {
      displayValue = shortNumber(value, options);
    } else if (method === 'asIt') {
      displayValue = value;
    } else if (method === 'bool') {
      displayValue = value ? trans(t, 'yes') : trans(t, 'no');
    } else if (method === 'date') {
      displayValue = dateString(value);
    } else if (method === 'datetime') {
      displayValue = dateString(value, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(value, 'HH:mm')!.replace(':', 'h');
    } else if (method === 'percentage') {
      displayValue = shortNumber(value, {precision: precision, symbol: '%'});
    } else if (method === 'label') {
      displayValue = value.label;
    } else if (method === 'list') {
      // Extract translated value from list items.
      displayValue = formatListItem(value, listItems);
    } else if (method === 'country') {
      // Map with backend country endpoint.
      forEach(listItems, (listItem: any) => {
        if (listItem.code === value || listItem.codeShort === value) {
          displayValue = listItem.name;
        }
      });
    } else if (typeof method === 'function') {
      displayValue = method(value);
    }
  }

  // Check if the displayed value is empty.
  const isEmptyValue = (null === displayValue || undefined === displayValue || '' === displayValue);

  return (
    <div className={classNames('flex-grow-1 indicateur indicateur--'+cssClassName(props.title)+' ' + props.color + (props.dark ? '-sombre' : '') + (props.class ? ' indicateur--' + props.class : ''), {
      'fond-image': props.bgImage,
      'mini': props.size === 'min',
      'maxi': props.size === 'max'
    })}>
      <span className={'titre'}>{ucfirst(props.title)}</span>
      <div className={'informations flex flex-row'}>
        { loading ? (
          <Skeleton width={'100%'} height={'4rem'}></Skeleton>
        ) : (
          <>
            <span className={classNames('flex flex-1 valeur')} title={props.valueTitle}>
              {isEmptyValue ? emptyValue : displayValue}
            </span>
            {props.percent && (
              <div className={'flex align-items-center pourcent-conteneur'}>
                <span className={'pourcent'}>{shortNumber(props.percent, {symbol: '%', precision: percentPrecision})}</span>
              </div>
            )}
            {props.tooltip && (
              <Tooltip target={'.indicateur--' + cssClassName(props.title)} position={'bottom'} content={props.tooltip} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
