import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { trans } from 'utilities';
import { useTranslation } from 'react-i18next';
import { useAppState, useUserState } from 'states';
import { Loader } from 'components';
import { isClient, userDossierId, userId } from 'services';

import appUri from 'config/appUri.json';

export const HomePage = () => {
  const { t } = useTranslation();
  const appState = useAppState();
  const userState = useUserState();
  const navigate = useNavigate();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.home', 1, userState.short() || ''));
    appState.setBreadcrumb([{ label: trans(t, 'menu|menu.home') }]);
    appState.setPageActions([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's redirect user according to his role.
  useEffect(() => {
    const navigateToPage = async () => {
      await navigate(
        isClient()
          ? userDossierId()
            ? appUri.dos.page.replace(':id', userDossierId() ?? '_')
            : appUri.usr.dir.page.replace(':id', userId())
          : appUri.dos.list
      );
    };

    navigateToPage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'grid'}>
      <div className={'col-12'}>
        <div className={'card'}>
          <Loader />
        </div>
      </div>
    </div>
  );
};
