import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';

import { useApim, isAdmin } from 'services';
import { useAppState } from 'states';
import {
  SettingsAlertCategoriesDatatable, SettingsAlertsDatatable, SettingsAverageMarketRatesDatatable, SettingsBankDatatable,
  SettingsDocumentTypesDatatable, SettingsExonerationDatatable, SettingsInflationRatesDatatable, SettingsInsuranceCompaniesDatatable,
  SettingsMinimumSalariesDatatable, SettingsMissionTypesDatatable, SettingsPassDatabase, SettingsRevenueDeclarationDatesDatatable,
  SettingsRetirementAgesDatatable, SettingsRoiRatesDatatable, SettingsSalaryTaxDatatable
} from 'components';
import { trans, ucfirst } from 'utilities';
import { NotFound } from 'pages';
import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const SettingsRouter = () => {
  const { tab, year } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const baseUri = appUri.set.general;
  const tabs: any[] = [];
  if (isAdmin()) {
    tabs.push({label: trans(t, 'menu|tab.social'), command: () => navigate(baseUri)});

    forEach(appUri.set.tabs, (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  useEffect(() => {
    let ai = 0;
    if (year) {
      ai = 1;
    } else {
      forEach(appUri.set.tabs, (ta, index) => {
        if (tab === ta) ai = index + 1;
      });
    }

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    if (isAdmin()) {
      appState.state().pageTitle.set(ucfirst(trans(t, 'settings')));
      appState.setBreadcrumb([{ label: trans(t, 'settings') }]);
    }
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    if (!isAdmin()) return <NotFound asBlock/>;

    switch (activeIndex) {
      case 0 :
        return (
          <>
            <SettingsRetirementAgesDatatable appState={appState} resourceType={'retirementAges'} tableKey={'retirement-ages'} title={ucfirst(trans(t, 'menu|pages.title.settings.retirementAges'))} apim={apim} />
            <SettingsPassDatabase appState={appState} resourceType={'pass'} tableKey={'passes'} title={ucfirst(trans(t, 'menu|pages.title.settings.pass'))} apim={apim} />
          </>
        )
      case 1 :
        return (
          <>
            <SettingsRevenueDeclarationDatesDatatable appState={appState} resourceType={'revenueDeclarationDates'} tableKey={'revenue-declaration-dates'} title={ucfirst(trans(t, 'menu|pages.title.settings.revenueDeclarationDates'))} apim={apim} />
            <SettingsSalaryTaxDatatable appState={appState} resourceType={'salaryTaxRates'} tableKey={'salary-tax-rates'} title={ucfirst(trans(t, 'menu|pages.title.settings.salaryTaxRates'))} apim={apim} baseUri={baseUri} year={year} />
          </>
        )
      case 2:
        return (
          <SettingsBankDatatable appState={appState} resourceType={'banks'} tableKey={'banks'} title={ucfirst(trans(t, 'menu|pages.title.settings.bank'))} apim={apim} />
        )
      case 3:
        return(
          <SettingsInsuranceCompaniesDatatable appState={appState} resourceType={'insuranceCompanies'} tableKey={'insurance-companies'} title={ucfirst(trans(t, 'menu|pages.title.settings.insuranceCompanies'))} apim={apim} />
        )
      case 4:
        return (
          <>
            <SettingsInflationRatesDatatable appState={appState} resourceType={'inflationRates'} tableKey={'inflation-rates'} title={ucfirst(trans(t, 'menu|pages.title.settings.inflationRates'))} apim={apim} />
            <SettingsAverageMarketRatesDatatable appState={appState} resourceType={'averageMarketRates'} tableKey={'average-market-rates'} title={ucfirst(trans(t, 'menu|pages.title.settings.averageMarketRates'))} apim={apim} />
            <SettingsMinimumSalariesDatatable appState={appState} resourceType={'minimumSalaries'} tableKey={'minimum-salaries'} title={ucfirst(trans(t, 'menu|pages.title.settings.minimumSalaries'))} apim={apim} />
            <SettingsRoiRatesDatatable appState={appState} resourceType={'roiRates'} tableKey={'roi-rates'} title={ucfirst(trans(t, 'menu|pages.title.settings.roiRates'))} apim={apim} />
            <SettingsExonerationDatatable appState={appState} resourceType={'exonerations'} tableKey={'exoneration'} title={ucfirst(trans(t, 'menu|pages.title.settings.exoneration'))} apim={apim} />
          </>
        )
      case 5 :
        return(
          <SettingsMissionTypesDatatable appState={appState} resourceType={'missionTypes'} tableKey={'mission-types'} title={ucfirst(trans(t, 'menu|pages.title.settings.missionTypes'))} apim={apim} />
        )
      case 6 :
        return(
          <>
            <SettingsAlertCategoriesDatatable appState={appState} resourceType={'alertCategories'} tableKey={'alertCategories'} title={ucfirst(trans(t, 'menu|pages.title.settings.alertCategories'))} apim={apim} />
            <SettingsAlertsDatatable appState={appState} resourceType={'alerts'} tableKey={'alerts'} title={ucfirst(trans(t, 'menu|pages.title.settings.alerts'))} apim={apim} />
          </>
        )
      case 7:
        return(
          <SettingsDocumentTypesDatatable appState={appState} resourceType={'documentTypes'} tableKey={'document-types'} title={ucfirst(trans(t, 'menu|pages.title.settings.documentTypes'))} apim={apim} />
        )
    }
  }, [tab, activeIndex, year]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && activeIndex !== -1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

