import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { trans, ucfirst } from 'utilities';
import {
  ActesSpecifiques,
  AddContractBeneficiaireForm, AddCreditBeneficiaireForm, AddDonationBeneficiaireForm, AddEconomicDataAccountOwnerForm,
  AddPersonnePhysiqueForm, AddSocieteClientForm, AddSocieteClientFormStandalone, AddPatrimoineForm,
  AddPrevoyanceContratBeneficiaireForm, Address, AddressSociete,
  AssuranceVieForm, AssuranceVieSelectionForm, CapitalisationForm, CapitalisationSelectionForm,
  BienBancaireSelectionForm, BienBancaireForm, BienDiversSelectionForm, BienDiversForm,
  BienEntrepriseSelectionForm, BienEntrepriseForm, BienImmobilierSelectionForm, BienImmobilierForm,
  CreditForm, CreditSelectionForm, DonationForm, DonationSelectionForm,
  CGOptionForm, CPContratForm, CPIncapaciteForm, CPInvaliditeForm, CPDecesForm,
  ContratsForm, ContratsFacultatifsForm, ContexteForm, CGContratForm, CGIncapaciteForm, CGInvaliditeForm, PrevoyanceContratValidationForm,
  DatesDepartForm,
  Documents,
  DonneesEconomiquesForm, DonneesFiscalesForm,
  DonneesSocialesCollectivesForm, DonneesSocialesDirigeantForm,
  DossierSettingsForm,
  DossierStep1,
  ImmatriculationSociete, ImmatriculationSocieteExperts,
  MissionRequestForm, MissionSelectionForm,
  MissionTypes,
  Notifications,
  PatrimoineForm, PatrimoineFoyerForm,
  PatrimoineContratRetraiteForm, PatrimoineContratRetraiteSelectionForm,
  PrevoyanceForm, PrevoyanceObligatoireForm,
  RemunerationDirigeantForm, RemunerationDirigeantStatutForm,
  RepresentantSocieteExperts,
  ReservesForm,
  RetraiteForm,
  SettingsAlert,
  Shortcuts,
  StatutDirigeantForm,
  SocieteClientEvent,
  TresorerieEtEmpruntForm, TresorerieGeneraleForm, TresorerieNegativeForm,
  UserForm, UserGroupForm, PersonnePhysiquePlusNestedForm,
  ZoneDeNoteForm
} from 'forms';

export const renderForm = (props: any) => {
  const {
    resourceType,
    formKey,
    parentKey,
    formKeyPrefix,
    fieldSetLabel,
    apim,
    control,
    context,
    onFieldChange,
    errorMessage,
    getValues,
    setValue,
    listsOptions,
    globalDisabled,
    setError,
    clearErrors
  } = props;
  const { t } = apim.di();
  let form = <></>;

  switch (resourceType) {
    case 'alerts' :
      form = <SettingsAlert t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} getValues={getValues}
                            setValue={setValue} formKey={formKey} parentKey={parentKey} listsOptions={listsOptions}/>;
      break;
    case 'personnesPhysiquesAddresses':
    case 'societesExpertsAdresses':
      form = <Address t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey}/>;
      break;

    case 'dossiers':
      form = <DossierSettingsForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                  getValues={getValues}/>
      break;

    case 'userGroups':
      form = <UserGroupForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            getValues={getValues} setValue={setValue} context={context} formKey={formKey} parentKey={parentKey} />
      break;

    case 'dossierCompanies':
      switch (formKeyPrefix) {
        case 'add_pm_dialog':
          form = <AddSocieteClientForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                       getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} useIndex={true}/>
          break;

        case 'add':
          form = <AddSocieteClientFormStandalone t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                                 getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix}/>
          break;

        default:
          form = <>
            <Fieldset legend={ucfirst(trans(t, 'immatriculation'))} toggleable>
              <ImmatriculationSociete t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                      getValues={getValues} setValue={setValue} globalDisabled={globalDisabled}
                                      formKey={formKey} parentKey={parentKey}/>
            </Fieldset>

            <Fieldset className={'my-3'} legend={ucfirst(trans(t, 'ent.headquarterAddress'))} toggleable>
              <AddressSociete t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              globalDisabled={globalDisabled} getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey}/>
            </Fieldset>

            <Fieldset legend={ucfirst(trans(t, 'acte', 2))} toggleable>
              <ActesSpecifiques t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                getValues={getValues} globalDisabled={globalDisabled}/>
            </Fieldset>
          </>
          break;
      }
      break;

    case 'dossierEvents':
      form = <SocieteClientEvent t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                 listsOptions={listsOptions}/>
      break;

    case 'societesExperts':
      form =
        <>
          <Fieldset legend={ucfirst(trans(t, 'immatriculation'))} toggleable>
            <ImmatriculationSocieteExperts t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                           getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey}/>
          </Fieldset>
        </>;
      break;

    case 'societesExpertsRepresentantsLegaux':
      form =
        <>
          <Fieldset legend={ucfirst(trans(t, 'representantLegal'))} toggleable>
            <RepresentantSocieteExperts t={t} control={control} onFieldChange={onFieldChange}
                                        errorMessage={errorMessage}/>
          </Fieldset>
        </>;
      break;

    case 'personnesPhysiques':
      switch (formKeyPrefix) {
        case 'add_pp_dialog':
          form =
            <AddPersonnePhysiqueForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                     getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix}
                                     context={context}/>
          break;

        default:
          form = <PersonnePhysiquePlusNestedForm t={t} control={control} onFieldChange={onFieldChange} formKey={formKey} parentKey={parentKey}
                                                 errorMessage={errorMessage} getValues={getValues} setValue={setValue}/>
          break;
      }
      break;

    case 'users':
      form =
        <UserForm apim={apim} t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                  context={context}
                  getValues={getValues} setError={setError} clearErrors={clearErrors} formKeyPrefix={formKeyPrefix}/>
      break;

    case 'shortcuts':
      form = <Shortcuts t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}/>
      break;

    case 'notifications':
      form = <Notifications t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            resourceType={resourceType}/>
      break;

    case 'wf_dossier':
      if (parentKey === 'step1') {
        form = <DossierStep1 t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} context={context} setError={setError}/>;
      } else {
        form =
          <Fieldset legend={fieldSetLabel || ucfirst(trans(t, 'immatriculation'))} toggleable>
            <div className={'grid'}>
              <ImmatriculationSociete t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                      getValues={getValues} setValue={setValue} resourceType={resourceType} formKey={formKey}
                                      parentKey={parentKey}/>
            </div>
          </Fieldset>;
      }
      break;

    case 'tresoreries':
      switch (formKeyPrefix) {
        case 'dossier_tresorerie_negative':
          form =
            <div className={'grid'}>
              <TresorerieNegativeForm t={t} control={control} onFieldChange={onFieldChange}
                                      errorMessage={errorMessage}/>
            </div>
          break;

        case 'dossier_tresorerie_emprunt':
          form =
            <div className={'grid'}>
              <TresorerieEtEmpruntForm t={t} control={control} onFieldChange={onFieldChange}
                                       errorMessage={errorMessage} getValues={getValues}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <TresorerieGeneraleForm t={t} control={control} onFieldChange={onFieldChange}
                                      errorMessage={errorMessage}/>
            </div>
          break;
      }
      break;

    case 'reserves':
      form =
        <div className={'grid'}>
          <ReservesForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}/>
        </div>
      break;

    case 'economicData':
      form =
        <div className={'grid'}>
          <DonneesEconomiquesForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                  getValues={getValues} setValue={setValue} context={context} apim={apim}/>
        </div>
      break;

    case 'fiscalData':
      form =
        <div className={'grid'}>
          <DonneesFiscalesForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                               formKey={formKey} getValues={getValues} setValue={setValue}/>
        </div>
      break;

    case 'socialCollectiveData':
      form =
        <div className={'grid'}>
          <DonneesSocialesCollectivesForm t={t} control={control} onFieldChange={onFieldChange}
                                          errorMessage={errorMessage}
                                          formKey={formKey} getValues={getValues} setValue={setValue}/>
        </div>
      break;

    case 'dossierSocialData':
      switch (formKeyPrefix) {
        case 'contrats':
          form =
            <div className={'grid'}>
              <ContratsForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            listsOptions={listsOptions}/>
            </div>
          break;

        case 'general':
        default:
          form =
            <div className={'grid'}>
              <DonneesSocialesDirigeantForm t={t} control={control} onFieldChange={onFieldChange}
                                            errorMessage={errorMessage} getValues={getValues} setValue={setValue}/>
            </div>
          break;
      }
      break;

    case 'patrimoines':
      switch (formKeyPrefix) {
        case 'add_patrimoine_dialog':
          form =
            <AddPatrimoineForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                               setValue={setValue} formKeyPrefix={formKeyPrefix} listsOptions={listsOptions}/>
          break;

        default:
          form =
            <div className={'grid'}>
               <PatrimoineForm apim={apim} t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                               getValues={getValues} setValue={setValue} context={context} parentKey={parentKey} formKey={formKey}/>
            </div>
          break;
      }
      break;

    case 'patrimoineFoyers':
      switch (formKeyPrefix) {
        default:
          form =
            <div className={'grid'}>
              <PatrimoineFoyerForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                   getValues={getValues} context={context} setValue={setValue} parentKey={parentKey} formKey={formKey}/>
            </div>
          break;
      }
      break;

    case 'biensBancaires':
      switch (formKeyPrefix) {
        case 'bien_bancaire_selection':
          form =
            <div className={'grid'}>
              <BienBancaireSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                         getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <BienBancaireForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} listsOptions={listsOptions}
                                getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'biensDivers':
      switch (formKeyPrefix) {
        case 'bien_divers_selection':
          form =
            <div className={'grid'}>
              <BienDiversSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                       getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <BienDiversForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              getValues={getValues} context={context}/>
            </div>
          break;
      }
      break;

    case 'biensEntreprises':
      switch (formKeyPrefix) {
        case 'bien_entreprise_selection':
          form =
            <div className={'grid'}>
              <BienEntrepriseSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                           getValues={getValues} context={context} globalDisabled={globalDisabled} listsOptions={listsOptions}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <BienEntrepriseForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                  getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'biensImmobiliers':
      switch (formKeyPrefix) {
        case 'bien_immobilier_selection':
          form =
            <div className={'grid'}>
              <BienImmobilierSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                            getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <BienImmobilierForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                  getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'contractsAssuranceVie':
      switch (formKeyPrefix) {
        case 'contract_assurance_vie_selection':
          form =
            <div className={'grid'}>
              <AssuranceVieSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                         getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <AssuranceVieForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} listsOptions={listsOptions}
                                getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'contractsCapitalisation':
      switch (formKeyPrefix) {
        case 'contract_capitalisation_selection':
          form =
            <div className={'grid'}>
              <CapitalisationSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                         getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <CapitalisationForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} listsOptions={listsOptions}
                                getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'contractsRetraite':
      switch (formKeyPrefix) {
        case 'contract_retraite_selection':
          form =
            <div className={'grid'}>
              <PatrimoineContratRetraiteSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                                      getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <PatrimoineContratRetraiteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} listsOptions={listsOptions}
                                             getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'contractBeneficiaires':
      form =
        <AddContractBeneficiaireForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                   getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={context}/>
      break;

    case 'credits':
      switch (formKeyPrefix) {
        case 'credit_selection':
          form =
            <div className={'grid'}>
              <CreditSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                   getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <CreditForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} listsOptions={listsOptions}
                          getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'creditBeneficiaires':
      form =
        <AddCreditBeneficiaireForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                   getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={context}/>
      break;

    case 'donations':
      switch (formKeyPrefix) {
        case 'donation_selection':
          form =
            <div className={'grid'}>
              <DonationSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                   getValues={getValues} context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        case 'zone_note':
          form =
            <div className={'grid'}>
              <ZoneDeNoteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              context={context} globalDisabled={globalDisabled}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <DonationForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} listsOptions={listsOptions}
                          getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey} context={context}/>
            </div>
          break;
      }
      break;

    case 'donationBeneficiaires':
      form =
        <AddDonationBeneficiaireForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                     getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={context}/>
      break;

    case 'economicDataAccounts':
      form =
        <AddEconomicDataAccountOwnerForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                         getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={context}/>
      break;

    case 'prevoyanceContractBeneficiairies':
      form =
        <AddPrevoyanceContratBeneficiaireForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                              getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={context}/>
      break;

    case 'prevoyances':
      switch (formKeyPrefix) {
        case 'contrat_validation':
          form =
            <div className={'grid'}>
              <PrevoyanceContratValidationForm t={t} control={control} onFieldChange={onFieldChange}
                                               errorMessage={errorMessage}/>
            </div>
          break;

        default:
          form =
            <div className={'grid'}>
              <PrevoyanceForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}/>
            </div>
          break;
      }
      break;
    case 'prevoyancesObligatoires':
      form =
        <div className={'grid'}>
          <PrevoyanceObligatoireForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                     getValues={getValues} context={context} apim={apim}/>
        </div>
      break;

    case 'prevoyanceContracts':
    case 'prevoyanceContractsData':
      switch (formKeyPrefix) {
        case 'contrat_contexte':
          form =
            <div className={'grid'}>
              <ContexteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            listsOptions={listsOptions}/>
            </div>
          break;

        case 'contrat_cg_contrat':
          form =
            <div className={'grid'}>
              <CGContratForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                             getValues={getValues}/>
            </div>
          break;

        case 'contrat_cg_incapacite':
          form = <>
            <div className={'grid'}>
              <CGIncapaciteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                getValues={getValues}/>
            </div>
            <div className={'grid'}>
              <CGOptionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            getValues={getValues}/>
            </div>
          </>
          break;

        case 'contrat_cg_invalidite':
          form =
            <div className={'grid'}>
              <CGInvaliditeForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                getValues={getValues}/>
            </div>
          break;

        case 'contrat_cp_contrat':
          form =
            <div className={'grid'}>
              <CPContratForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                             getValues={getValues} listsOptions={listsOptions} context={context} apim={apim}/>
            </div>
          break;

        case 'contrat_cp_incapacite':
          form =
            <div className={'grid'}>
              <CPIncapaciteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                getValues={getValues}/>
            </div>
          break;

        case 'contrat_cp_invalidite':
          form =
            <div className={'grid'}>
              <CPInvaliditeForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                getValues={getValues} listsOptions={listsOptions}/>
            </div>
          break;

        case 'contrat_cp_deces':
          form =
            <div className={'grid'}>
              <CPDecesForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           getValues={getValues} context={context} apim={apim}/>
            </div>
          break;
      }
      break;
    case 'documents':
      form =
        <div className={'grid'}>
          <Documents t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       getValues={getValues} context={context} apim={apim}/>
        </div>
      break;

    case 'missionRetraite':
      switch (formKeyPrefix) {
        case 'general_retraite':
          form =
            <div className={'grid'}>
              <RetraiteForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            context={context} setValue={setValue} formKey={formKey} parentKey={parentKey} getValues={getValues}/>
            </div>
          break;

        case 'dates_depart_retraite':
          form =
            <div className={'grid'}>
              <DatesDepartForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}/>
            </div>
          break;
      }
      break;

    case 'mission_statut_dirigeant':
          form =
            <div className={'grid'}>
              <StatutDirigeantForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            context={context}/>
            </div>
          break;

    case 'mission_remuneration_dirigeant':
      form =
        <div className={'grid'}>
          <RemunerationDirigeantForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                               context={context} setValue={setValue} formKey={formKey} parentKey={parentKey} getValues={getValues}/>
        </div>
      break;

    case 'missionRemunerationStatut':
      form =
        <div className={'grid'}>
          <RemunerationDirigeantStatutForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                     context={context}/>
        </div>
      break;

    case 'missionRequest':
      form =
        <div className={'grid'}>
          <MissionRequestForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                           context={context} setValue={setValue} formKey={formKey} parentKey={parentKey}/>
        </div>
      break;

    case 'missions':
      form =
        <div className={'grid'}>
          <MissionSelectionForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                context={context} setValue={setValue} formKey={formKey} parentKey={parentKey}/>
        </div>
      break;

    case 'missionRetraiteContracts':
      form =
        <div className={'grid'}>
          <ContratsFacultatifsForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                                   getValues={getValues}/>
        </div>
      break;

    case 'societeExpertMissionTypes':
      form =
        <div className={'grid'}>
          <MissionTypes control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} />
        </div>
      break;

  }

  return form;
};
