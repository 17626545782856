import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { amountCell, apiListCell, DatatableWrapper, dateTimeCell, personCell } from 'components';
import { IRequestParams, isAdmin, isClient, isExpert } from 'services';

import appUri from 'config/appUri.json';

export const PatrimoineDonationsDatatable = (props: any) => {
  const { apim, appState, context, title, editMode } = props;
  const { t, navigate } = apim.di();

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<any[]>([]);

  useEffect(() => {
    apim.getList('donationTypes', {setter: setTypes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const resourceType: string = 'patrimoineDonations';

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      params: [
        {label: 'patrimoine', value: context?.id},
        {label: 'expand[]', value: 'donation:read'},
        {label: 'expand[]', value: 'personne_physique:read'}
      ],
      setter: setRows,
      cache: editMode === false,
      setLoading,
      formatter: (_arr: any[]) => _arr.map((row: any) => ({...row?.donation, ...{id: row?.id, donationId: row?.donation?.id}}))
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    return (!isValidUUID(appState?.dossier()?.id) || !isValidUUID(context?.id)) ? null :
    navigate(appUri.dos.edit.patrimoine.donations.uri.replace(':id', appState?.dossier()?.id).replace('/:donationId?', ''));
  };

  const labelBody = (rowData: any) =>
    <div className={'flex flex-column'}>
      <div className={'font-medium'}>{rowData?.label}</div>
      <Link className={'block mt-1 text-sm'} to={editMode ?
        appUri.dos.edit.patrimoine.donations.uri
          .replace(':id', appState?.dossier()?.id)
          .replace(':donationId?', rowData?.donationId) + appUri.dos.edit.patrimoine.donations.steps.default.step2 :
        appUri.dos.page.replace(':id', appState?.dossier()?.id) + '/patrimoine/biens/donation/' + rowData?.donationId
      }>{trans(t, 'seeDetails')}</Link>
    </div>
  ;

  const editBody = (rowData: any) =>
    <>
      <Button type={'button'} className={'a8-details-btn'} icon={'pi pi-pencil'} rounded text aria-label={ucfirst(trans(t, 'edit'))} onClick={() => {
        navigate(appUri.dos.edit.patrimoine.donations.uri
          .replace(':id', appState?.dossier()?.id)
          .replace(':donationId?', rowData?.donationId) + appUri.dos.edit.patrimoine.donations.steps.default.step2);
      }}
      />
      <Tooltip target={'.a8-details-btn'} position={'left'} content={ucfirst(trans(t, 'edit'))} mouseTrack/>
    </>
  ;

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'patrimoine-donations'} title={title ?? trans(t, 'donation', 2)} editMode={editMode} noEdit
                      onNew={onNew} noAdd={isClient()} noFilters noGlobalFilter paginator={false} rows={rows} isLoading={loading}>
      <Column field={'label'} header={ucfirst(trans(t, 'table|patrimoine_headers.label'))} body={labelBody}/>
      <Column field={'type'} header={ucfirst(trans(t, 'table|patrimoine_headers.type'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => apiListCell(rowData?.type, types)}/>
      <Column field={'personnePhysique'} header={ucfirst(trans(t, 'form|patrimoine.donations.default.quiDonne'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => personCell(rowData?.personnePhysique, {
                url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
                label: trans(t, 'seeDetails')
              })}/>
      <Column field={'montant'} header={ucfirst(trans(t, 'table|patrimoine_headers.montant'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => amountCell(rowData?.montant)}/>
      <Column field={'date'} header={ucfirst(trans(t, 'table|patrimoine_headers.date'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => dateTimeCell(rowData?.date, {format: 'DD/MM/YYYY'})}/>
      {!editMode && (isExpert() || isAdmin()) && (
        <Column body={editBody} align={'center'} style={{ maxWidth: '90px', width: '90px' }} />
      )}
    </DatatableWrapper>
  );
};
