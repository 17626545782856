import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { amountCell, apiListCell, DatatableWrapper, dateTimeCell } from 'components';
import { IRequestParams, isAdmin, isClient, isExpert } from 'services';

import appUri from 'config/appUri.json';

export const PatrimoineBiensEntreprisesDatatable = (props: any) => {
  const { apim, appState, context, title, editMode } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [categoriesRevenus, setCategoriesRevenus] = useState<any[]>([]);
  useEffect(() => {
    apim.getList('bienEntrepriseTypes', {setter: setTypes} as IRequestParams).then();
    apim.getList('categoriesRevenus', {setter: setCategoriesRevenus} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const resourceType: string = 'patrimoineBiensEntreprises';

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      params: [
        {label: 'patrimoine', value: context?.id},
        {label: 'expand[]', value: 'bien_entreprise:read'},
        {label: 'expand[]', value: 'dossier_company:read'},
        {label: 'expand[]', value: 'dossier_company:read_latest_version'},
        {label: 'expand[]', value: 'dossier_company_data:read_list'}
      ],
      cache: editMode === false,
      setter: setRows,
      setLoading,
      formatter: (_arr: any[]) => _arr.map((row: any) => ({...row?.bienEntreprise, ...{id: row?.id, bienEntrepriseId: row?.bienEntreprise?.id}}))
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    return (!isValidUUID(appState?.dossier()?.id) || !isValidUUID(context?.id)) ? null :
    navigate(appUri.dos.edit.patrimoine.biens.uri.replace(':id', appState?.dossier()?.id).replace(':type', 'entreprise').replace('/:bienId?', ''));
  };

  const labelBodyTemplate = (rowData: any) =>
    <div className={'flex flex-column'}>
      <div className={'font-medium'}>{rowData?.company?.latestVersion?.raisonSociale ?? rowData?.company}</div>
      <Link className={'block mt-1 text-sm'} to={editMode ?
        appUri.dos.edit.patrimoine.biens.uri
          .replace(':id', appState?.dossier()?.id)
          .replace(':type', 'entreprise')
          .replace(':bienId?', rowData?.bienEntrepriseId) + appUri.dos.edit.patrimoine.biens.steps.default.step2 :
        appUri.dos.page.replace(':id', appState?.dossier()?.id) + '/patrimoine/biens/entreprise/' + rowData?.bienEntrepriseId
      }>{trans(t, 'seeDetails')}</Link>
    </div>
  ;
  const amountBodyTemplate = (rowData: any) => amountCell(rowData?.montantPleinePropriete);
  const categorieRevenusBodyTemplate = (rowData: any) => apiListCell(rowData?.categorieRevenus, categoriesRevenus);

  const editBody = (rowData: any) =>
    <>
      <Button type={'button'} className={'a8-details-btn'} icon={'pi pi-pencil'} rounded text aria-label={ucfirst(trans(t, 'edit'))} onClick={() => {
        navigate(appUri.dos.edit.patrimoine.biens.uri
          .replace(':id', appState?.dossier()?.id)
          .replace(':type', 'entreprise')
          .replace(':bienId?', rowData?.bienEntrepriseId) + appUri.dos.edit.patrimoine.biens.steps.default.step2);
      }}
      />
      <Tooltip target={'.a8-details-btn'} position={'left'} content={ucfirst(trans(t, 'edit'))} mouseTrack/>
    </>
  ;

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'patrimoine-biens-entreprises'} title={title ?? trans(t, 'table|patrimoine_bien.entreprise')}
                      editMode={editMode} noEdit onNew={onNew} noAdd={isClient()} noFilters noGlobalFilter paginator={false} rows={rows} isLoading={loading} footer={[
      { field: 'montantPleinePropriete', calculation: 'sum', label: ucfirst(trans(t, 'total')) + ' : ' }]} t={t}>
      <Column field={'label'} header={ucfirst(trans(t, 'table|patrimoine_headers.label'))} body={labelBodyTemplate}/>
      <Column field={'type'} header={ucfirst(trans(t, 'table|patrimoine_headers.type'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => apiListCell(rowData?.type, types)}/>
      <Column field={'family'} header={ucfirst(trans(t, 'table|patrimoine_headers.categorieRevenus'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={categorieRevenusBodyTemplate}/>
      <Column field={'purchaseDate'} header={ucfirst(trans(t, 'table|patrimoine_headers.purchaseDate'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => dateTimeCell(rowData?.purchaseDate, {format: 'DD/MM/YYYY'})}/>
      <Column field={'montantPleinePropriete'} header={ucfirst(trans(t, 'table|patrimoine_headers.valeur'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={amountBodyTemplate}/>
      {!editMode && (isExpert() || isAdmin()) && (
        <Column body={editBody} align={'center'} style={{ maxWidth: '90px', width: '90px' }} />
      )}
    </DatatableWrapper>
  );
};
