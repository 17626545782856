import { JSX } from 'react';

import currency from 'currency.js';
import moment from 'moment';
import 'moment-timezone';

import { camelCase, forEach, isEmpty, omit, pick, trim, upperCase } from 'lodash';
import apimConfig from 'config/apimConfig.json';
import appConfig from 'config/appConfig.json';

interface IMontantOptions {
  symbol: string
  separator: string
  decimal: string
  pattern: string
  negativePattern: string
  precision: number
  forcePrecision: boolean,
  nullValue: string
}

const defaultMontantOptions: IMontantOptions = {
  symbol: '€',
  separator: ' ',
  decimal: ',',
  pattern: '# !',
  negativePattern: '-# !',
  precision: 2,
  forcePrecision: false,
  nullValue: ''
};

export const montant = (val: any, options: any = {}) => {
  const mergedOptions = { ...defaultMontantOptions, ...options };
  if (null === val || undefined === val) return mergedOptions.nullValue;

  // Particular cases.
  let precision = mergedOptions.precision;
  const fixed = val.toFixed(mergedOptions.precision);
  if (fixed * 1000 % 1000 === 0 && !mergedOptions.forcePrecision) {
    precision = precision > 2 ? 2 : precision;
  }
  if (fixed * 100 % 100 === 0 && !mergedOptions.forcePrecision) {
    precision = precision > 1 ? 1 : precision;
  }
  if (fixed * 10 % 10 === 0 && !mergedOptions.forcePrecision) {
    precision = precision > 0 ? 0 : precision;
  }

  return currency(val, { ...defaultMontantOptions, ...mergedOptions, ...{ precision: precision } }).format();
};

export const shortNumber = (val: any, options: any = {}): string => {
  if (null === val || undefined === val) return '';
  const mergedOptions = { ...defaultMontantOptions, ...{ precision: 0 }, ...options };

  let shortVal: number = parseFloat(val);
  let unitPrefix: string = '';

  if (Math.abs(val) >= 1000) {
    if (Math.abs(val) >= 1000000) {
      if (Math.abs(val) >= 1000000000) {
        shortVal = val / 1000000000;
        unitPrefix = 'B';
      } else {
        shortVal = val / 1000000;
        unitPrefix = 'M';
      }
    } else {
      shortVal = val / 1000;
      unitPrefix = 'k';
    }
  }
  mergedOptions.symbol = unitPrefix + mergedOptions.symbol

if (mergedOptions.exactValue === true) {
  return montant(val);
}
  return montant(shortVal, mergedOptions);
};

export const period = (val: number | null): string => {
  if (!val) {
    return 'NR';
  }
  const totalMonths: number = val * 12;
  const annees: number = Math.floor(totalMonths / 12);
  const mois: number = Math.floor(totalMonths - (annees * 12));
  return annees + (annees > 1 ? ' ans' : ' an') + (mois > 0 ? ' et ' + mois + ' mois' : '');
};

export const escapeValue = (val: any, format: string = 'default') => {
  // First switch with !string formats.
  switch (format) {
    case 'boolean':
    case 'date':
    case 'join':
      return val;
    default:
      break;
  }

  // First switch with string formats.
  let formattedValue = trim(val);

  // Then handle ambiguous formats.
  if ('true' === formattedValue) return true;
  if ('false' === formattedValue) return false;
  if ('null' === formattedValue || '' === formattedValue) return null;

  // Finally standards formats.
  switch (format) {
    case 'camelCase':
      return camelCase(formattedValue);

    case 'number':
      return parseFloat(formattedValue);

    case 'removeSpaces':
      return formattedValue.replace(/\s/g, '');

    case 'upperCase':
      return upperCase(formattedValue);

    default:
      break;
  }

  return formattedValue;
};

export const humanFileSize = (bytes: number, si: boolean = true, decimal: number = 0): string => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** decimal;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(decimal) + ' ' + units[u];
};

const defaultDateFormats: string[] = appConfig.datetime.defaultDateFormats;
export const dateString = (date: any, format: string = appConfig.datetime.defaultDateFormat, options: any | null = null) => {
  return date ? moment(date, defaultDateFormats)
    .tz(options?.tz ?? appConfig.datetime.defaultTimezone)
    .format(format) : null;
}

export const dateAgoString = (date: any, options: any | null = null) => date ? moment(date, defaultDateFormats).tz(options?.tz ?? appConfig.datetime.defaultTimezone).fromNow() : null;

export const asDate = (dateString: string, format: string[] = defaultDateFormats, options: any | null = null) => moment(dateString, format).tz(options?.tz ?? appConfig.datetime.defaultTimezone).toDate();

export const age = (date: any, suffix: string = 'an', prefix = '', options: any | null = null) => {
  let years: number = moment().tz(options?.tz ?? appConfig.datetime.defaultTimezone).diff(date, 'years', false);

  if (!isEmpty(options?.referenceDate)) {
    const yearsReference: number = moment().tz(options?.tz ?? appConfig.datetime.defaultTimezone).diff(options.referenceDate, 'years', false);
    years -= yearsReference;
  }

  if (isEmpty(suffix)) {
    return years;
  }

  const ageString = years + ' ' + suffix + (years > 1 ? 's' : '');
  return '' !== prefix ? prefix + ' ' + dateString(date, appConfig.datetime.defaultDateFormat, options) + ' (' + ageString + ')' : ageString;
};

export const startsAsDate = (date: string | null = null) => {
  const regex_pattern: string = '^[0-9]{4}-[0-9]{2}-[0-9]{2}';
  return date ? date.match(regex_pattern) : false; // check the string with regex
}

export const ucfirst = (string: string) => string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

export const iri = (resourceType: string, resourceId: string, withoutAPI: boolean = false) => {
  const c = apimConfig.resources as any;
  if (undefined === c[resourceType] || !c[resourceType] || null === c[resourceType]['uri']) return (withoutAPI ? '/' : '/api/') + resourceType + '/' + resourceId;

  return (withoutAPI ? '/' : '/api/') + c[resourceType]['uri'] + '/' + resourceId;
};

/**
 * Extract an entity UUID from an IRI string.
 *
 * @param iri
 *   IRI string?.
 *
 * @return string
 *   UUID string.
 */
export const uuidFromIri = (iri: string | null = null): string => {
  if (!iri) return '';

  const lastSlashIndex = iri.lastIndexOf('/');
  return iri.substring(lastSlashIndex + 1);
};

/**
 * Format a list item value as translated string.
 *
 * @param value
 *   List item value.
 * @param listItems
 *   List item values.
 *
 * @return string
 *   List item translated value.
 */
export const formatListItem = (value: string | null = null, listItems: any[]): string | null => {
  let displayValue: string | null = value;

  forEach(listItems, (listItem: any) => {
    const listItemId = listItem['@id'].substring(listItem['@id'].lastIndexOf('/') + 1)?.toLowerCase();
    const valueLower = value?.toLowerCase();
    if (listItemId === valueLower || listItem.value === value) {
      displayValue = listItem.translated;
    }
  });

  return displayValue;
};

/**
 * Format a siren.
 *
 * @param siren
 *   Siren string?.
 *
 * @return string
 *   Formatted Siren.
 */
export const formatSiren = (siren: string | null = null): string => {
  if (!siren) return '';

  return siren.substring(0, 3) + ' ' + siren.substring(3, 6) + ' ' + siren.substring(6, 9);
};

/**
 * Format a siret.
 *
 * @param siret
 *   Siret string?.
 *
 * @return string
 *   Formatted Siret.
 */
export const formatSiret = (siret: string | null = null): string => {
  if (!siret) return '';

  return siret.substring(0, 3) + ' ' + siret.substring(3, 6) + ' ' + siret.substring(6, 9) + ' ' + siret.substring(9);
};

export const handleCopy = (text: string) => {
  navigator.clipboard.writeText(text);
};

// Let's flat all fields to root level to simplify nested forms.
export const flatPerson = (person: any) => {
  return {
    ...omit(person, ['latestVersion', 'birthDate', 'deceasedDate']),
    ...omit(person?.latestVersion, ['address']),
    ...(person?.latestVersion?.address?.id ? pick(person?.latestVersion, ['address'])['address'] : {}),
    ...{
      personnePhysiqueId: person?.id,
      addressId: person?.latestVersion?.address?.id,
      birthDate: dateString(person?.birthDate),
      deceasedDate: dateString(person?.deceasedDate)
    }
  }
};

export const flatPrevoyanceContract = (contract: any, contractData: any) => {
  return {
    ...omit(contract, ['latestVersion']),
    ...{ data: contractData }
  }
};

/**
 * Transform a string to a CSS class name.
 *
 * @param string
 */
export const cssClassName = (string: string | null) => {
  if (string === null) return null;

  return string.replace(/[^a-z0-9]/g, (s) => {
    const c = s.charCodeAt(0);
    if (c === 32) return '-';
    if (c >= 65 && c <= 90) return '_' + s.toLowerCase();
    return '__' + ('000' + c.toString(16)).slice(-4);
  });
}

export const displayHtml = (htmlContent: string | undefined): JSX.Element => {
  if (!htmlContent) return <div>NR</div>;

  const tokenRegex = /{[^}]+}/g;
  const highlightTokens = (content: string) => {
    return content.replace(tokenRegex, (match) => `<span class='text-primary'>${match}</span>`);
  };

  return <div dangerouslySetInnerHTML={{ __html: highlightTokens(htmlContent) }} />;
};
