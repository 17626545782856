import React from 'react';
import { Column } from 'primereact/column';

import { trans, ucfirst } from 'utilities';
import { simpleCheckboxEditor, DatatableWrapper, dateTimeCell, validCell, maskEditor, dateEditor } from 'components';

export const SettingsRevenueDeclarationDatesDatatable = (props: any) => {
  const { lazyConfig, apim, tableKey, title, resourceType } = props;
  const { t } = apim.di();

  const lazyC = { ...{ sortField: 'date', sortOrder: -1 }, ...lazyConfig };
  const dateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date, { format: 'YYYY' });
  const dateMonthYearBodyTemplate = (rowData: any) => dateTimeCell(rowData?.dateMonthYear, {
    format: 'DD/MM',
    subRow: false
  });

  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey} lazyConfig={lazyC} title={title} editMode
                      noFilters noGlobalFilter editFields={['dateMonthYear', 'date', 'active']}>
      <Column field={'dateMonthYear'} header={ucfirst(trans(t, 'revenueDeclarationDates'))} sortable
              body={dateMonthYearBodyTemplate}
              editor={(options) => maskEditor(options, {label: trans(t, 'date'), isDate: true, mask:'99/99'})}/>
      <Column field={'date'} body={dateBodyTemplate} header={ucfirst(trans(t, 'otherYear'))} sortable align={'center'}
              editor={(options) => dateEditor(options, { label: trans(t, 'otherYear'), dateFormat: 'yy', view: 'year' })} style={{ width: '20vw' }} />
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
              style={{ width: '15vw' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
              style={{ width: '15vw' }} />
      <Column field={'active'} header={trans(t, 'active')} sortable align={'center'} body={activeBodyTemplate}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'table|active') })}
              style={{ width: '5vw' }} />
    </DatatableWrapper>
  );
};
