import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';

import { IRequestParams } from 'services';
import { isValidUUID, trans, ucfirst } from 'utilities';
import {
  DatatableWrapper,
  apiListCell, dateTimeCell, multilinesCell, validCell,
  apiListEditor, simpleCheckboxEditor, staticListEditor, textEditor,
} from 'components';

import { find, isArray, omit } from 'lodash';

export const SettingsDocumentTypesDatatable = (props: any) => {
  const { apim, tablekey, title, lazyConfig, resourceType } = props;
  const { t } = apim.di();
  const [dateTypes, setDateTypes] = useState<any[]>([]);
  const [modules, setModules] = useState<any[]>([]);

  const lazyC = {
    ...{
      sortField: 'name',
      sortOrder: 1,
    }, ...lazyConfig
  };

  // Load document date types list
  useEffect(() => {
    apim.getList('dateTypes', { setter: setDateTypes } as IRequestParams).then();
    apim.fetchEntities({
      resourceType: 'modules',
      params: [
        {label: 'order[fullLabel]', value: 'asc'},
        {label: 'itemsPerPage', value: 500}
      ],
      setter: setModules
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const moduleEditorTemplate = (options: any) => {
    // @todo when new row is added then edited, data flashes in empty row
    const interVal: any[] = isArray(options?.rowData?.modules) ? options?.rowData?.modules : [options?.rowData?.modules];

    return (
      <div className={'flex flex-column align-items-center w-full'} style={{maxWidth: '240px'}}>
        {staticListEditor(options, {
          label: trans(t, 'brique', 2),
          listKey: 'modules',
          listsOptions: { modules },
          optionLabel: 'fullLabel',
          optionValue: 'id',
          multiple: true,
          value: (interVal ?? []).map((_m: any) => find(modules, (m: any) => isValidUUID(_m) ? _m === m.id : _m?.module === m['@id'])),
          classes: ['w-full max-w-full'],
        })}
      </div>
    )
  };

  const modulesTemplate = (rowData: any) => {
    if (modules.length < 1) return <Skeleton width={'100%'} height={'2.8rem'}/>
    const t = (rowData?.modules ?? [])
      .map((_m: any) => find(modules, (m: any) => isValidUUID(_m) ? _m === m.id : _m?.module === m['@id']));

    return (t ?? []).length > 0 ? multilinesCell(t, 'fullLabel', true, true) : '';
  };

  const onSubmit = (id: string | null, patched: any/*, rows: any[]*/) => {
    const data: any = omit(patched, ['id', 'modules', '@id', '@type', 'created', 'updated']);

    // Check if we just edited modules.
    if ((patched.modules ?? []).length > 0) {
      if (isValidUUID(patched.modules[0])) {
        data.modules = patched.modules.map((_m: string) => { return {module: _m}; });
      }
    }

    if (id) {
      apim.patchEntity({
        resourceType,
        id,
        data
      } as IRequestParams).then();
    } else {
      apim.postEntity({
        resourceType,
        data
      } as IRequestParams).then();
    }
  };

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tablekey} title={title} editMode onSubmit={onSubmit} lazyConfig={lazyC} autoEditOnNewRow={true}
                      params={[{label: 'expand[]', value: 'document_type:read_modules'}, {label: 'expand[]', value: 'document_type_module:read'}]}
                      editFields={['modules', 'type', 'name', 'internal', 'companyRelated', 'active']} noFilters>
      <Column field={'name'} header={ucfirst(trans(t, 'name'))} sortable
              editor={(options) => textEditor(options, { label: trans(t, 'name') })} />
      <Column field={'type'} header={ucfirst(trans(t, 'type'))} align={'center'} sortable
              body={(rowData: any) => apiListCell(rowData?.type, dateTypes)}
              editor={(options) => apiListEditor(options, {
                label: trans(t, 'menu|tag.filetype'),
                listKey: 'dateTypes'
              })} style={{ width: '120px', maxWidth: '120px' }}/>
      <Column field={'modules'} header={trans(t, 'brique', 2)} body={modulesTemplate} editor={moduleEditorTemplate}
              style={{ width: '12vw', maxWidth: '12vw' }} />
      <Column field={'internal'} header={trans(t, 'internal')} sortable align={'center'} body={(rowData: any) => validCell(rowData?.internal)}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'internal') })}
              style={{ width: '8vw' }}/>
      <Column field={'companyRelated'} header={trans(t, 'companyRelated')} sortable align={'center'}
              body={(rowData: any) => validCell(rowData?.companyRelated)}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'companyRelated') })}
              style={{ width: '8vw' }}/>
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={(rowData: any) => dateTimeCell(rowData?.updated)}
              style={{ width: '10vw' }}/>
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={(rowData: any) => dateTimeCell(rowData?.created)}
              style={{ width: '10vw' }}/>
      <Column field={'active'} header={trans(t, 'active')} sortable align={'center'} body={(rowData: any) => validCell(rowData?.active)}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'table|active') })}
              style={{ width: '5vw' }}/>
    </DatatableWrapper>
  );
};
