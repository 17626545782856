import React from 'react';

import { classNames } from 'primereact/utils';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

import { ucfirst } from 'utilities';

export const EditorField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, label, classes, addon, addonPosition, format, disabled, help, textAddon, hideLabel } = props;

  const renderInput = (field: any, fieldState: any) => {
    return (
      <>
        <div className='relative w-full'>
          {!(hideLabel || false) && (
            <label htmlFor={field.name} className={classNames('absolute quill-label ml-2 px-2 text-blue-700 bg-white',)}>{ucfirst(label)}</label>
          )}

          <ReactQuill
            id={field.name} className={classNames('mb-3 quill', {'p-invalid': fieldState.error})} value={field.value || ''} theme={'snow'} readOnly={disabled}
            onChange={(content: any, delta: any, source: any, editor: { getHTML: () => any; }) => {
              onFieldChange(field, fieldState, editor.getHTML(), format || 'default');
            }}/>
        </div>

        {help && (<span className={'help text-xs pt-2 block'}>{help}</span>)}
      </>
    );
  };

  return (
    <Controller
      name={fieldKey}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={classes}>
          {addon && addonPosition === 'left' && <span className='p-inputgroup-addon'>{addon}</span>}
          {renderInput(field, fieldState)}
          {addon && addonPosition === 'right' && <span className='p-inputgroup-addon'>{addon}</span>}
          {textAddon && <span className='text-addon'>{textAddon}</span>}
          {errorMessage && fieldState.error && <small className='p-error'>{errorMessage}</small>}
        </div>
      )}
    />
  );
};
