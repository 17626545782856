import React from 'react';

import { LoadError, LocalizationMap, Viewer } from '@react-pdf-viewer/core';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import DocViewer, { DocRenderer, DocViewerRenderers } from '@cyntler/react-doc-viewer';

import { useApim } from 'services';
import { trans } from 'utilities';

interface DocumentPreview {
  extension: string;
  name?: string;
  filename?: string;
  uri: string;
}

interface DocumentViewerProps {
  documentPreview: DocumentPreview;
  viewerRef: any;
}

export const DocumentViewer: React.FC<DocumentViewerProps> = ({ documentPreview, viewerRef }) => {
  const apim = useApim();
  const { t } = apim.di();

  const pdfViewerCustomRenderError = (error: LoadError) => {
    let message = '';
    switch (error.name) {
      case 'InvalidPDFException':
        message = trans(t, 'system|error.pdfViewer.InvalidPDFException');
        break;
      case 'MissingPDFException':
        message = trans(t, 'system|error.pdfViewer.MissingPDFException');
        break;
      case 'UnexpectedResponseException':
        message = trans(t, 'system|error.pdfViewer.UnexpectedResponseException');
        break;
      default:
        message = trans(t, 'system|error.pdfViewer.default');
        break;
    }

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: '#fff',
            padding: '0.5rem',
          }}
        >
          {message}
        </div>
      </div>
    );
  };

  // Let's remove unwanted toolbar items.
  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
  });
  const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  const pluginInstance = defaultLayoutPlugin({
    renderToolbar,
    setInitialTab: () => Promise.resolve(0),
  });
  const { renderDefaultToolbar } = pluginInstance.toolbarPluginInstance;

  let AllDocViewerRenderers: DocRenderer[] = DocViewerRenderers;

  return (
    <>
      {documentPreview?.extension === 'pdf' || documentPreview?.name?.endsWith('.pdf') || documentPreview?.filename?.endsWith('.pdf')? (
        <Viewer
          fileUrl={documentPreview?.uri}
          localization={fr_FR as unknown as LocalizationMap}
          renderError={pdfViewerCustomRenderError}
          plugins={[pluginInstance]}
        />
      ) : (
        <DocViewer
          language="fr"
          prefetchMethod="GET"
          ref={viewerRef}
          documents={[documentPreview]}
          pluginRenderers={AllDocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: false,
            },
            pdfVerticalScrollByDefault: true,
          }}
        />
      )}
    </>
  );
};
