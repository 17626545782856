import React from 'react';

import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { classNames } from 'primereact/utils';

import { UncontrolledBaseField } from './UncontrolledBaseField';
import { trans } from 'utilities';
import { useApim } from 'services';

export const UncontrolledEditorField = (props: any) => {
  const { fieldKey, value, onFieldChange, label, help, description, classes, addon, addonPosition, disabled, height, hideLabel } = props;
  const apim = useApim();
  const { t } = apim.di();
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
    ],
  };

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description}>
      {!(hideLabel || false) && (
        <label className={'text-ucfirst'} htmlFor={fieldKey}>
        <span>{label || trans(t, fieldKey)}</span>
      </label>
        )}
      <ReactQuill id={fieldKey} className={classNames('text-ucfirst w-full mt-2 mb-7')} value={value || ''} readOnly={disabled} modules={modules}
                  onChange={(content: any, delta: any, source: any, editor: { getHTML: () => any; }) => onFieldChange(editor.getHTML())} theme={'snow'}
                  style={{ height: height || '70px'}}/>
      {help && (
        <span className={'help text-xs pt-2 block'}>{help}</span>
      )}
    </UncontrolledBaseField>
  );
};
